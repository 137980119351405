import {Inject, Injectable, InjectionToken} from '@angular/core'
import {StorageService as NGXWebstorage} from 'ngx-webstorage-service'

export const MY_STORAGE_SERVICE = new InjectionToken<StorageService>('MY_STORAGE_SERVICE')

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private readonly keyPrefix = 'ngx-webstorage|'

  public get<T>(key: string): T {
    return this.storage.get(this.keyPrefix + key)
  }

  public set (key: string, value: unknown): void {
    this.storage.set(this.keyPrefix + key, value)
  }

  public delete (key: string): void {
    this.storage.remove(this.keyPrefix + key)
  }

  public has (key: string): boolean {
    return this.storage.has(this.keyPrefix + key)
  }

  public constructor (@Inject(MY_STORAGE_SERVICE) private readonly storage: NGXWebstorage) {}
}
