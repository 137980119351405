import {IEnvironment} from "./IEnvironment";

export const environment: IEnvironment = {
  version_update_method: "next_route",
  production: false,
  env_name: 'stage',
  api: {
    url: 'https://api.bmw.teraone-preview.de/api/external/signage/v1',
    session_domain: '.bmw.teraone-preview.de'
  }
}
