import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./home/home.component";

const routes: Routes = [
  {
    path: 'landscape',
    component: HomeComponent,
    data: {
      mode: 'landscape'
    }
  },
  {
    path: 'skyscraper',
    component: HomeComponent,
    data: {
      mode: 'skyscraper'
    }
  },
  {
    path: '**',
    redirectTo: 'landscape'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      enableTracing: false,
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled',
      paramsInheritanceStrategy: 'always',
      canceledNavigationResolution: 'replace', // Default
      urlUpdateStrategy: 'deferred', // Default
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
