import {Injector, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule, HttpClientXsrfModule} from "@angular/common/http";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {AppRoutingModule} from "./modules/app-routing.module";
import {ApiInterceptor} from "./services/api/api.interceptor";
import {HomeComponent} from "./modules/home/home.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ServicesModule} from "./services/services.module";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";

// AoT requires an exported function for factories
// httpBackend is used to init a new HttpClient instead of using the present one
// Using the already present HttpClient causes a Circular dependency
export function createTranslateLoader(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend), 'assets/i18l/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpBackend]
      }
    }),
    BrowserAnimationsModule,
    ServicesModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: undefined,
      serverLoggingUrl: undefined
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      deps: [Injector],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
