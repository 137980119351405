<body [class.landscape]="mode === 'landscape'" [class.skyscraper]="mode === 'skyscraper'">
<div class="position-relative h-100 w-100" [@fade]="contentVisible ? 'visible' : 'hidden'">
  <h1 class="text-uppercase fw-400 mb-1">{{ 'headline' | translate }}</h1>
  <h2 class="text-uppercase fw-300">{{ 'subline' | translate }}</h2>
  <div class="clock">{{ currentTime | date: (currentLang === 'en' ? 'hh:mm a' : 'HH:mm') }}</div>
  <table [class.landscape]="mode === 'landscape'" [class.skyscraper]="mode === 'skyscraper'">
    <thead>
    <tr>
      <th class="time" [class.landscape]="mode === 'landscape'" [class.skyscraper]="mode === 'skyscraper'">{{ 'table.headers.datetime' | translate }}</th>
      <th class="article">{{ 'table.headers.article_name' | translate }}</th>
      <th class="language">{{ 'table.headers.language' | translate }}</th>
      <th class="slots" [class.landscape]="mode === 'landscape'" [class.skyscraper]="mode === 'skyscraper'">{{ 'table.headers.remaining_slots' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let slot of slots; let first = first; let index = index">
      <tr [class.d-none]="(mode === 'landscape' && index > 3) || (mode === 'skyscraper' && index > 8)">
        <td class="time" [class.landscape]="mode === 'landscape'" [class.skyscraper]="mode === 'skyscraper'">
            <span *ngIf="first && minutesTillFirstSlotStart && minutesTillFirstSlotStart <= 60; else defaultTimeDisplay">
              <ng-container *ngIf="minutesTillFirstSlotStart >= 1">
                {{ 'table.content.datetime.minutes' | translate: {time: minutesTillFirstSlotStart} }}
              </ng-container>
              <ng-container *ngIf="minutesTillFirstSlotStart <= 0">
                {{ 'table.content.datetime.now' | translate }}
              </ng-container>
            </span>
          <ng-template #defaultTimeDisplay>
            {{ 'table.content.datetime.hours' | translate: {time: (slot.start_datetime | date: (currentLang === 'en' ? 'hh:mm a' : 'HH:mm'))} }}
          </ng-template>
        </td>
        <td class="article">
          <p>{{ $any(slot.article_title)[currentLang] }}</p>
        </td>
        <td class="language">
          <span *ngIf="slot.language">{{ ('table.content.language.' + (slot.language === SlotLanguageEnum.GERMAN ? 'de' : 'en')) | translate }}</span>
        </td>
        <td class="slots" [class.landscape]="mode === 'landscape'" [class.skyscraper]="mode === 'skyscraper'">
          {{ 'table.content.remaining_slots' | translate: {
          slot_count: (slot.remaining_contingent > 99 ? 99 : (slot.remaining_contingent | number: '2.0') ) }
          }}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="!slots || (slots && slots.length <= 0)">
      <tr>
        <td colspan="4" class="text-center">
          {{ 'table.no_slots_text' | translate }}
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <div *ngIf="mode === 'skyscraper'" class="qr-code-info">
    <p class="fw-300" [innerHTML]="('qr_code_text' | translate)"></p>
    <img *ngIf="currentLang === 'en'" src="/assets/images/tkts-en-qr-code.png" alt="https://https://tkts.bmw-welt.com/en">
    <img *ngIf="currentLang === 'de'" src="/assets/images/tkts-de-qr-code.png" alt="https://https://tkts.bmw-welt.com/de">
  </div>
</div>
</body>
