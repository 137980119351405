export interface SingleResponse<T> {
  data: T;
}

export interface PaginatedResponse<T> {
  data: T[];
  meta: PaginationMetaData;
}

export interface PaginationMetaData {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}
export enum PERMISSION {
  ACCOUNTING_DAYENDCLOSING_CREATE_ALL = "accounting_dayendclosing.create.*",
  ACCOUNTING_DAYENDCLOSING_DELETE_ALL = "accounting_dayendclosing.delete.*",
  ACCOUNTING_DAYENDCLOSING_READ_ALL = "accounting_dayendclosing.read.*",
  ACCOUNTING_DAYENDCLOSING_UPDATE_ALL = "accounting_dayendclosing.update.*",
  ACCOUNTING_DOCUMENT_CREATE_ALL = "accounting_document.create.*",
  ACCOUNTING_DOCUMENT_DELETE_ALL = "accounting_document.delete.*",
  ACCOUNTING_DOCUMENT_READ_ALL = "accounting_document.read.*",
  ACCOUNTING_DOCUMENT_UPDATE_ALL = "accounting_document.update.*",
  ADMINISTRATIONS_CONTENTELEMENT_CREATE_ALL = "administrations_contentelement.create.*",
  ADMINISTRATIONS_CONTENTELEMENT_DELETE_ALL = "administrations_contentelement.delete.*",
  ADMINISTRATIONS_CONTENTELEMENT_READ_ALL = "administrations_contentelement.read.*",
  ADMINISTRATIONS_CONTENTELEMENT_UPDATE_ALL = "administrations_contentelement.update.*",
  ADMINISTRATIONS_EMAIL_CREATE_ALL = "administrations_email.create.*",
  ADMINISTRATIONS_EMAIL_DELETE_ALL = "administrations_email.delete.*",
  ADMINISTRATIONS_EMAIL_READ_ALL = "administrations_email.read.*",
  ADMINISTRATIONS_EMAIL_UPDATE_ALL = "administrations_email.update.*",
  ADMINISTRATIONS_PAGE_CREATE_ALL = "administrations_page.create.*",
  ADMINISTRATIONS_PAGE_DELETE_ALL = "administrations_page.delete.*",
  ADMINISTRATIONS_PAGE_READ_ALL = "administrations_page.read.*",
  ADMINISTRATIONS_PAGE_UPDATE_ALL = "administrations_page.update.*",
  ATTACHMENT_CREATE_ALL = "attachment.create.*",
  ATTACHMENT_DELETE_ALL = "attachment.delete.*",
  ATTACHMENT_READ_ALL = "attachment.read.*",
  ATTACHMENT_UPDATE_ALL = "attachment.update.*",
  BOOKINGS_SLOT_CREATE_ALL = "bookings_slot.create.*",
  BOOKINGS_SLOT_DELETE_ALL = "bookings_slot.delete.*",
  BOOKINGS_SLOT_READ_ALL = "bookings_slot.read.*",
  BOOKINGS_SLOT_UPDATE_ALL = "bookings_slot.update.*",
  DEVELOPMENT_CREATE_ALL = "development.create.*",
  DEVELOPMENT_DELETE_ALL = "development.delete.*",
  DEVELOPMENT_READ_ALL = "development.read.*",
  DEVELOPMENT_UPDATE_ALL = "development.update.*",
  GUEST_CREATE_ALL = "guest.create.*",
  GUEST_DELETE_ALL = "guest.delete.*",
  GUEST_READ_ALL = "guest.read.*",
  GUEST_UPDATE_ALL = "guest.update.*",
  ORDERS_CART_CART_CREATE_ALL = "orders_cart_cart.create.*",
  ORDERS_CART_CART_DELETE_ALL = "orders_cart_cart.delete.*",
  ORDERS_CART_CART_READ_ALL = "orders_cart_cart.read.*",
  ORDERS_CART_CART_UPDATE_ALL = "orders_cart_cart.update.*",
  ORDERS_ORDER_CREATE_ALL = "orders_order.create.*",
  ORDERS_ORDER_DELETE_ALL = "orders_order.delete.*",
  ORDERS_ORDER_READ_ALL = "orders_order.read.*",
  ORDERS_ORDER_UPDATE_ALL = "orders_order.update.*",
  ORDERS_TICKET_CREATE_ALL = "orders_ticket.create.*",
  ORDERS_TICKET_DELETE_ALL = "orders_ticket.delete.*",
  ORDERS_TICKET_READ_ALL = "orders_ticket.read.*",
  ORDERS_TICKET_UPDATE_ALL = "orders_ticket.update.*",
  REPORTING_TEMPLATE_CREATE_ALL = "reporting_template.create.*",
  REPORTING_TEMPLATE_DELETE_ALL = "reporting_template.delete.*",
  REPORTING_TEMPLATE_READ_ALL = "reporting_template.read.*",
  REPORTING_TEMPLATE_UPDATE_ALL = "reporting_template.update.*",
  RESOURCES_GUIDE_CREATE_ALL = "resources_guide.create.*",
  RESOURCES_GUIDE_DELETE_ALL = "resources_guide.delete.*",
  RESOURCES_GUIDE_READ_ALL = "resources_guide.read.*",
  RESOURCES_GUIDE_UPDATE_ALL = "resources_guide.update.*",
  SETTINGS_ARTICLE_CREATE_ALL = "settings_article.create.*",
  SETTINGS_ARTICLE_DELETE_ALL = "settings_article.delete.*",
  SETTINGS_ARTICLE_READ_ALL = "settings_article.read.*",
  SETTINGS_ARTICLE_UPDATE_ALL = "settings_article.update.*",
  SETTINGS_BOOKINGQUESTION_CREATE_ALL = "settings_bookingquestion.create.*",
  SETTINGS_BOOKINGQUESTION_DELETE_ALL = "settings_bookingquestion.delete.*",
  SETTINGS_BOOKINGQUESTION_READ_ALL = "settings_bookingquestion.read.*",
  SETTINGS_BOOKINGQUESTION_UPDATE_ALL = "settings_bookingquestion.update.*",
  SETTINGS_CANCELLATIONREASON_CREATE_ALL = "settings_cancellationreason.create.*",
  SETTINGS_CANCELLATIONREASON_DELETE_ALL = "settings_cancellationreason.delete.*",
  SETTINGS_CANCELLATIONREASON_READ_ALL = "settings_cancellationreason.read.*",
  SETTINGS_CANCELLATIONREASON_UPDATE_ALL = "settings_cancellationreason.update.*",
  SETTINGS_DISCOUNTREASON_CREATE_ALL = "settings_discountreason.create.*",
  SETTINGS_DISCOUNTREASON_DELETE_ALL = "settings_discountreason.delete.*",
  SETTINGS_DISCOUNTREASON_READ_ALL = "settings_discountreason.read.*",
  SETTINGS_DISCOUNTREASON_UPDATE_ALL = "settings_discountreason.update.*",
  SETTINGS_LANGUAGESKILL_CREATE_ALL = "settings_languageskill.create.*",
  SETTINGS_LANGUAGESKILL_DELETE_ALL = "settings_languageskill.delete.*",
  SETTINGS_LANGUAGESKILL_READ_ALL = "settings_languageskill.read.*",
  SETTINGS_LANGUAGESKILL_UPDATE_ALL = "settings_languageskill.update.*",
  SETTINGS_LOCATION_CREATE_ALL = "settings_location.create.*",
  SETTINGS_LOCATION_DELETE_ALL = "settings_location.delete.*",
  SETTINGS_LOCATION_READ_ALL = "settings_location.read.*",
  SETTINGS_LOCATION_UPDATE_ALL = "settings_location.update.*",
  SETTINGS_MUSEUMSCHEDULING_CREATE_ALL = "settings_museumscheduling.create.*",
  SETTINGS_MUSEUMSCHEDULING_READ_ALL = "settings_museumscheduling.read.*",
  SETTINGS_SEGMENT_CREATE_ALL = "settings_segment.create.*",
  SETTINGS_SEGMENT_DELETE_ALL = "settings_segment.delete.*",
  SETTINGS_SEGMENT_READ_ALL = "settings_segment.read.*",
  SETTINGS_SEGMENT_UPDATE_ALL = "settings_segment.update.*",
  SETTINGS_SETTLEMENTACCOUNT_CREATE_ALL = "settings_settlementaccount.create.*",
  SETTINGS_SETTLEMENTACCOUNT_DELETE_ALL = "settings_settlementaccount.delete.*",
  SETTINGS_SETTLEMENTACCOUNT_READ_ALL = "settings_settlementaccount.read.*",
  SETTINGS_SETTLEMENTACCOUNT_UPDATE_ALL = "settings_settlementaccount.update.*",
  SETTINGS_TABLEFILTERFAVORITE_CREATE_ALL = "settings_tablefilterfavorite.create.*",
  SETTINGS_TABLEFILTERFAVORITE_DELETE_ALL = "settings_tablefilterfavorite.delete.*",
  SETTINGS_TABLEFILTERFAVORITE_READ_ALL = "settings_tablefilterfavorite.read.*",
  SETTINGS_TABLEFILTERFAVORITE_UPDATE_ALL = "settings_tablefilterfavorite.update.*",
  SETTINGS_TARGETAUDIENCE_CREATE_ALL = "settings_targetaudience.create.*",
  SETTINGS_TARGETAUDIENCE_DELETE_ALL = "settings_targetaudience.delete.*",
  SETTINGS_TARGETAUDIENCE_READ_ALL = "settings_targetaudience.read.*",
  SETTINGS_TARGETAUDIENCE_UPDATE_ALL = "settings_targetaudience.update.*",
  SETTINGS_VENUE_CREATE_ALL = "settings_venue.create.*",
  SETTINGS_VENUE_DELETE_ALL = "settings_venue.delete.*",
  SETTINGS_VENUE_READ_ALL = "settings_venue.read.*",
  SETTINGS_VENUE_UPDATE_ALL = "settings_venue.update.*",
  USER_CREATE_ALL = "user.create.*",
  USER_DELETE_ALL = "user.delete.*",
  USER_READ_ALL = "user.read.*",
  USER_UPDATE_ALL = "user.update.*",
  VEHICLECONSULTATIONS_AVAILABILITY_CREATE_ALL = "vehicleconsultations_availability.create.*",
  VEHICLECONSULTATIONS_AVAILABILITY_DELETE_ALL = "vehicleconsultations_availability.delete.*",
  VEHICLECONSULTATIONS_AVAILABILITY_READ_ALL = "vehicleconsultations_availability.read.*",
  VEHICLECONSULTATIONS_AVAILABILITY_UPDATE_ALL = "vehicleconsultations_availability.update.*",
  VEHICLECONSULTATIONS_AVAILABILITYSETTING_CREATE_ALL = "vehicleconsultations_availabilitysetting.create.*",
  VEHICLECONSULTATIONS_AVAILABILITYSETTING_DELETE_ALL = "vehicleconsultations_availabilitysetting.delete.*",
  VEHICLECONSULTATIONS_AVAILABILITYSETTING_READ_ALL = "vehicleconsultations_availabilitysetting.read.*",
  VEHICLECONSULTATIONS_AVAILABILITYSETTING_UPDATE_ALL = "vehicleconsultations_availabilitysetting.update.*",
  VEHICLECONSULTATIONS_BOOKINGS_BOOKING_CREATE_ALL = "vehicleconsultations_bookings_booking.create.*",
  VEHICLECONSULTATIONS_BOOKINGS_BOOKING_DELETE_ALL = "vehicleconsultations_bookings_booking.delete.*",
  VEHICLECONSULTATIONS_BOOKINGS_BOOKING_READ_ALL = "vehicleconsultations_bookings_booking.read.*",
  VEHICLECONSULTATIONS_BOOKINGS_BOOKING_UPDATE_ALL = "vehicleconsultations_bookings_booking.update.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESK_CREATE_ALL = "vehicleconsultations_counsellingdesks_counsellingdesk.create.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESK_DELETE_ALL = "vehicleconsultations_counsellingdesks_counsellingdesk.delete.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESK_READ_ALL = "vehicleconsultations_counsellingdesks_counsellingdesk.read.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESK_UPDATE_ALL = "vehicleconsultations_counsellingdesks_counsellingdesk.update.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESKAVAILABILITY_CREATE_ALL = "vehicleconsultations_counsellingdesks_counsellingdeskavailability.create.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESKAVAILABILITY_DELETE_ALL = "vehicleconsultations_counsellingdesks_counsellingdeskavailability.delete.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESKAVAILABILITY_READ_ALL = "vehicleconsultations_counsellingdesks_counsellingdeskavailability.read.*",
  VEHICLECONSULTATIONS_COUNSELLINGDESKS_COUNSELLINGDESKAVAILABILITY_UPDATE_ALL = "vehicleconsultations_counsellingdesks_counsellingdeskavailability.update.*",
  VEHICLECONSULTATIONS_GENIUS_GENIUSAVAILABILITY_CREATE_ALL = "vehicleconsultations_genius_geniusavailability.create.*",
  VEHICLECONSULTATIONS_GENIUS_GENIUSAVAILABILITY_DELETE_ALL = "vehicleconsultations_genius_geniusavailability.delete.*",
  VEHICLECONSULTATIONS_GENIUS_GENIUSAVAILABILITY_READ_ALL = "vehicleconsultations_genius_geniusavailability.read.*",
  VEHICLECONSULTATIONS_GENIUS_GENIUSAVAILABILITY_UPDATE_ALL = "vehicleconsultations_genius_geniusavailability.update.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLE_CREATE_ALL = "vehicleconsultations_vehicles_vehicle.create.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLE_DELETE_ALL = "vehicleconsultations_vehicles_vehicle.delete.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLE_READ_ALL = "vehicleconsultations_vehicles_vehicle.read.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLE_UPDATE_ALL = "vehicleconsultations_vehicles_vehicle.update.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLEAVAILABILITY_CREATE_ALL = "vehicleconsultations_vehicles_vehicleavailability.create.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLEAVAILABILITY_DELETE_ALL = "vehicleconsultations_vehicles_vehicleavailability.delete.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLEAVAILABILITY_READ_ALL = "vehicleconsultations_vehicles_vehicleavailability.read.*",
  VEHICLECONSULTATIONS_VEHICLES_VEHICLEAVAILABILITY_UPDATE_ALL = "vehicleconsultations_vehicles_vehicleavailability.update.*",
}
export namespace Backend {
  export namespace App.Data {
    export type GuestData = {
      state: App.Enums.ModelStateEnum;
      salutation: App.Enums.Guests.GuestSalutationEnum;
      title: App.Enums.Guests.GuestTitleEnum | null;
      first_name: string;
      last_name: string;
      company: string | null;
      street: string | null;
      street_number: string | null;
      zip_code: string | null;
      city: string | null;
      country: App.Enums.CountryCodeISOAlpha3Enum;
      phone_number: string | null;
      email: string | null;
      vat_id: string | null;
      ib_cost_center: string | null;
      ib_department: string | null;
      comment: string | null;
      locale: App.Enums.Guests.GuestLocaleEnum;
      target_audience_ids: Array<App.Data.Meta.UuidAttributeData> | null;
      attachments: Array<App.Data.Meta.UuidAttributeData> | null;
    };
  }
  export namespace App.Data.Accounting {
    export type DayEndClosingData = {
      confirm_operator_mismatch: boolean | null;
      safebag_number: string | null;
      comment: string | null;
      counted_amount: number | null;
      reasons_for_difference: Array<App.Enums.Ecos.EcosDayEndClosingDifferenceReasonEnum> | null;
    };
  }
  export namespace App.Data.Administrations {
    export type ContentElementData = {
      state: App.Enums.ModelStateEnum;
      title: string;
      content: App.Data.Meta.MultilingualAttributeData;
    };
    export type EmailData = {
      type: App.Enums.Administrations.EmailTypeEnum;
      title: string;
      subject: App.Data.Meta.MultilingualAttributeData;
      content: App.Data.Meta.MultilingualAttributeData;
      pre_content: App.Data.Meta.MultilingualAttributeData;
      post_content: App.Data.Meta.MultilingualAttributeData;
      is_primary: boolean;
    };
    export type PageBySlugData = {
      slug: string;
    };
    export type PageData = {
      state: App.Enums.ModelStateEnum;
      slug: string;
      title: App.Data.Meta.MultilingualAttributeData;
      content: App.Data.Meta.MultilingualAttributeData;
      meta_description: App.Data.Meta.MultilingualAttributeData;
      sitemap_priority: number;
      include_in_sitemap: boolean;
      index_follow: boolean;
    };
  }
  export namespace App.Data.Attachments {
    export type AttachmentData = {
      type?: App.Enums.AttachmentTypeEnum;
      path: string;
      name: string;
      mimeType?: string | null;
      imageWidth?: number | null;
      imageHeight?: number | null;
      size?: number | null;
      disk?: string;
      file: any | null;
      attachment?: any | null;
    };
  }
  export namespace App.Data.Auth {
    export type DashboardLoginData = {
      email: string;
      password: string;
    };
    export type SingleSignOnLoginData = {
      code: string;
      iss: string;
      client_id: string;
      state: any | string;
    };
    export type UserData = {
      locale: string | null;
      email_signature: string | null;
    };
  }
  export namespace App.Data.Bookings {
    export type SlotData = {
      state: App.Enums.ModelStateEnum;
      article_id: string;
      type: App.Enums.Bookings.SlotTypeEnum;
      start_datetime: string;
      end_datetime: string;
      venue_id: string;
      max_contingent: number;
      language: App.Enums.Bookings.SlotLanguageEnum | null;
      hints: string | null;
      resource_ids: Array<App.Data.Meta.UuidAttributeData> | null;
      attachments: Array<App.Data.Meta.UuidAttributeData> | null;
    };
  }
  export namespace App.Data.Meta {
    export type MultilingualAttributeData = {
      de: string;
      en: string;
    };
    export type TitleAttributeData = {
      title: string;
    };
    export type UuidAttributeData = {
      id: string;
    };
  }
  export namespace App.Data.Orders {
    export type DashboardAggregatedOrderItemData = {
      aggregated_id: string;
      booking_slot_id: string;
      article_price_version_id: string | null;
      discount_reason_version_id?: string | null;
      quantity: number;
      vc_comparison_vehicle_id?: string | null;
    };
    export type DashboardOrderAttachmentsUpdateData = {
      attachments: Array<App.Data.Meta.UuidAttributeData> | null;
    };
    export type DashboardOrderBookingQuestionData = {
      booking_slot_id: string;
      question_id: string;
      answer_ids: Array<any> | null;
      custom_answer: string | null;
    };
    export type DashboardOrderData = {
      cart_id: string;
      guest_id: string;
      payment_service_provider: App.Enums.Orders.Payment.PaymentServiceProviderEnum;
    };
    export type DashboardOrderEmailData = {
      cc_recipients: Array<any>;
      bcc_recipients: Array<any>;
      subject: string | null;
      pre_content: string | null;
      post_content: string | null;
      email_id: string;
    };
    export type DashboardOrderPaymentMethodData = {
      payment_method: App.Enums.Orders.Payment.PaymentServiceProviderEnum;
      cost_center: string | null;
      custom_email_data?: App.Data.Orders.DashboardOrderEmailData;
    };
    export type DashboardOrderUpdateData = {
      customer_service_comment?: string | null;
      customer_service_state?: App.Enums.Orders.OrderCustomerServiceStateEnum | null;
      customer_service_proposal_valid_until?: string | null;
      customer_service_proposal_payment_type?: App.Enums.Orders.OrderCustomerServiceProposalPaymentTypeEnum | null;
      aggregated_items: Array<App.Data.Orders.DashboardAggregatedOrderItemData>;
      cancellation_reason_id?: string | null;
      booking_questions: Array<App.Data.Orders.DashboardOrderBookingQuestionData>;
      custom_email_data?: App.Data.Orders.DashboardOrderEmailData;
    };
    export type WebshopOrderData = {
      cart_id: string;
      guest: App.Data.Orders.WebshopOrderGuestData;
    };
    export type WebshopOrderGuestData = {
      first_name: string;
      last_name: string;
      email: string;
      email_confirmation: string;
      is_company: boolean;
      company: string | null;
      street: string | null;
      street_number: string | null;
      zip_code: string | null;
      city: string | null;
      phone_number: string | null;
      country: string;
    };
    export type WebshopOrderItemData = {
      id: string;
    };
    export type WebshopOrderUpdateData = {
      aggregated_items: Array<App.Http.Resources.Ticketshop.Orders.AggregatedOrderItem>;
      cancellation_reason_id: string;
    };
  }
  export namespace App.Data.Orders.Cart {
    export type CartBookingQuestionData = {
      booking_slot_id: string;
      question_id: string;
      answer_ids: Array<any> | null;
      custom_answer: string | null;
    };
    export type CartData = {
      cart_items: Array<App.Data.Orders.Cart.CartItemData>;
      booking_questions?: Array<App.Data.Orders.Cart.CartBookingQuestionData>;
    };
    export type CartItemData = {
      booking_slot_id: string;
      article_price_id: string;
      discount_reason_id: string | null;
      quantity: number;
      vehicle_consultation_data?: App.Data.Orders.Cart.CartItemVehicleConsultationData | null;
    };
    export type CartItemVehicleConsultationData = {
      vehicle_id: string;
      booking_type: App.Enums.VehicleConsultations.BookingTypeEnum;
      comparison_vehicle_id: string | null;
    };
  }
  export namespace App.Data.Orders.Payment {
    export type ThreeDSecurePSPData = {
      fingerprint_result: string | null;
      challenge_result: string | null;
    };
    export type ThreeDSecurePaymentData = {
      type: string;
      psp_data: App.Data.Orders.Payment.ThreeDSecurePSPData;
    };
    export type WebshopPaymentData = {
      payment_provider: App.Enums.Orders.Payment.PaymentProviderEnum;
      payment_method_type: App.Enums.Orders.Payment.PaymentMethodTypeEnum;
      psp_data: Array<any> | null;
    };
  }
  export namespace App.Data.Reporting {
    export type GenerateReportData = {
      filters: Array<any>;
    };
    export type ReportingFilterData = {
      field: string;
      type: App.Enums.TableConfigColumnTypeEnum;
      operator: App.Enums.Reporting.ReportingFilterOperatorTypeEnum;
      value: string | null;
    };
  }
  export namespace App.Data.Resources {
    export type GuideData = {
      state: App.Enums.ModelStateEnum;
      first_name: string;
      last_name: string;
      department: string;
      phone_number: string;
      email: string;
      language_skill_ids: Array<App.Data.Meta.UuidAttributeData> | null;
      article_ids: Array<App.Data.Meta.UuidAttributeData> | null;
      image: App.Data.Meta.UuidAttributeData | null;
      attachments: Array<App.Data.Meta.UuidAttributeData> | null;
      is_guide: boolean;
      is_genius: boolean;
      bmw_employee_number: string | null;
      guide_setup_time?: number;
      genius_setup_time?: number;
      genius_qualifications?: Array<App.Enums.VehicleConsultations.GeniusQualificationEnum>;
    };
  }
  export namespace App.Data.Settings {
    export type BookingQuestionAnswerOptionData = {
      id: string | null;
      answer_option: App.Data.Meta.MultilingualAttributeData;
    };
    export type BookingQuestionData = {
      state: App.Enums.ModelStateEnum;
      question: App.Data.Meta.MultilingualAttributeData;
      response_type: App.Enums.Settings.BookingQuestionResponseTypeEnum;
      is_required: boolean;
      is_multiselect: boolean;
      answer_options: Array<App.Data.Settings.BookingQuestionAnswerOptionData> | null;
    };
    export type CancellationReasonData = {
      title: App.Data.Meta.MultilingualAttributeData;
      state: App.Enums.ModelStateEnum;
      sales_channels: Array<App.Enums.Settings.SalesChannelEnum>;
    };
    export type DiscountReasonData = {
      title: App.Data.Meta.MultilingualAttributeData;
      state: App.Enums.ModelStateEnum;
      reason_for_reduced_price: boolean;
      reason_for_free_price: boolean;
      sales_channels: Array<App.Enums.Settings.Article.OnlineShopFilterEnum>;
      location_ids: Array<App.Data.Meta.UuidAttributeData> | null;
    };
    export type LanguageSkillData = {
      title: string;
      state: App.Enums.ModelStateEnum;
    };
    export type LocationData = {
      title: string;
      street: string;
      house_number: string;
      zip_code: string;
      city: string;
      image: App.Data.Meta.UuidAttributeData | null;
      attachments: Array<App.Data.Meta.UuidAttributeData> | null;
      country: App.Enums.CountryCodeISOAlpha3Enum;
      description: string | null;
      state: App.Enums.ModelStateEnum;
    };
    export type MuseumSchedulingData = {
      article_id: string;
      venue_id: string;
      from: string;
      until: string;
      max_contingent: number;
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
      type: App.Enums.Bookings.SlotTypeEnum;
    };
    export type SegmentData = {
      title: string;
      state: App.Enums.ModelStateEnum;
    };
    export type SettlementAccountData = {
      title: string;
      cost_center_internal_payments: number;
      cost_center_external_payments: number;
      cost_unit: number;
      state: App.Enums.ModelStateEnum;
    };
    export type TableFilterFavoriteData = {
      title: string;
      column_order: string | null;
      hidden_columns: string | null;
      per_page: number;
      sorts: string;
      filter: Array<any>;
      table_id: string;
    };
    export type TargetAudienceData = {
      title: string;
      state: App.Enums.ModelStateEnum;
    };
    export type VenueData = {
      title: string;
      building_name: string;
      starting_point: string;
      street: string;
      house_number: string;
      zip_code: string;
      color: string;
      city: string;
      country: App.Enums.CountryCodeISOAlpha3Enum;
      hints: string | null;
      additional_information_link: string | null;
      state: App.Enums.ModelStateEnum;
      image: App.Data.Meta.UuidAttributeData | null;
      attachments: Array<App.Data.Meta.UuidAttributeData> | null;
    };
  }
  export namespace App.Data.Settings.Articles {
    export type ArticleByOnlineShopFilterAndDateData = {
      online_shop_filter: App.Enums.Settings.Article.OnlineShopFilterEnum;
      selected_date: string;
    };
    export type ArticleByOnlineShopFilterData = {
      online_shop_filter: App.Enums.Settings.Article.OnlineShopFilterEnum;
    };
    export type ArticleByShopAndDateData = {
      online_shop_filter: App.Enums.Settings.Article.OnlineShopFilterEnum;
      selected_date: string;
    };
    export type ArticleData = {
      state: App.Enums.ModelStateEnum;
      internal_title: string;
      title: App.Data.Meta.MultilingualAttributeData;
      short_description: App.Data.Meta.MultilingualAttributeData;
      description: App.Data.Meta.MultilingualAttributeData;
      external_article_id: string;
      location_id: string;
      public_cancellation_period: number;
      includes_museum_admission: boolean;
      settlement_account_id: string;
      sales_channels: Array<App.Enums.Settings.SalesChannelEnum>;
      slot_type: App.Enums.Bookings.SlotTypeEnum;
      segment_ids: Array<App.Data.Meta.UuidAttributeData> | null;
      booking_question_ids: Array<App.Data.Meta.UuidAttributeData> | null;
      online_shop_filter: Array<App.Enums.Settings.Article.OnlineShopFilterEnum>;
      prices: Array<App.Data.Settings.Articles.ArticlePriceData> | null;
      attachments: Array<App.Data.Meta.UuidAttributeData> | null;
      dashboard_category: App.Enums.Bookings.DashboardCategoryEnum;
      image: App.Data.Meta.UuidAttributeData | null;
      ticket_shop_order: number | null;
      default_slot_duration_in_minutes: number | null;
    };
    export type ArticlePriceData = {
      id: string | null;
      title: App.Data.Meta.MultilingualAttributeData;
      description: App.Data.Meta.MultilingualAttributeData;
      net_price: number;
      gross_price: number;
      type: App.Enums.Settings.Article.ArticlePriceTypeEnum;
      tickets_per_price: number;
      min_quantity: number;
      max_quantity: number;
      is_time_based_discounted: boolean;
      time_based_discount_type: App.Enums.Settings.Article.TimeBasedDiscountTypesEnum | null;
      external_price_id: string;
      group_id: number;
      group_item_order: number;
      valid_from: string;
      valid_until: string;
    };
  }
  export namespace App.Data.Settings.Articles.Availability {
    export type CheckData = {
      online_shop_filter: App.Enums.Settings.Article.OnlineShopFilterEnum;
      selected_start_date: any | string | null;
      article_id?: string | null;
    };
  }
  export namespace App.Data.VehicleConsultations {
    export type AvailabilityData = {
      start_at: string;
      end_at: string;
    };
    export type AvailabilitySettingData = {
      start_at: string;
      end_at: string;
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
    };
    export type CheckAvailabilityData = {
      selected_start_date: any | string | null;
    };
    export type CounsellingDeskAvailabilityData = {
      counselling_desk_id: string;
      start_at: string;
      end_at: string;
      is_absence?: boolean;
    };
    export type CounsellingDeskBookingData = {
      new_counselling_desk_id: string;
    };
    export type CounsellingDeskData = {
      state: App.Enums.ModelStateEnum;
      title: string;
      venue_id: string;
      digital_link: string | null;
      setup_time: number;
      characteristics: Array<App.Enums.VehicleConsultations.CounsellingDeskCharacteristicEnum>;
    };
    export type GeniusAvailabilityData = {
      guide_id: string;
      available_at: string;
      type: App.Enums.VehicleConsultations.GeniusAvailabilityTypeEnum;
    };
    export type GeniusAvailabilityImportData = {
      file: any;
    };
    export type GeniusBookingData = {
      new_genius_id: string;
    };
    export type SlotDashboardTableData = {};
    export type VehicleAvailabilityData = {
      type: App.Enums.VehicleConsultations.VehicleAvailabilityTypeEnum;
      vehicle_id: string;
      start_at: string;
      end_at: string;
      is_absence?: boolean;
    };
    export type VehicleAvailableConsultationSlotData = {
      booking_type: App.Enums.VehicleConsultations.BookingTypeEnum;
      date: string;
      vehicle_to_compare: string | null;
    };
    export type VehicleData = {
      brand: App.Enums.VehicleConsultations.VehicleBrandEnum;
      color: App.Data.Meta.MultilingualAttributeData;
      construction_types: Array<App.Enums.VehicleConsultations.VehicleConstructionTypeEnum>;
      detail_image: App.Data.Meta.UuidAttributeData | null;
      fuel_type: App.Enums.VehicleConsultations.VehicleFuelTypeEnum;
      icons: Array<App.Enums.VehicleConsultations.VehicleIconTypeEnum>;
      series: App.Enums.VehicleConsultations.VehicleSeriesEnum;
      state: App.Enums.ModelStateEnum;
      thumbnail: App.Data.Meta.UuidAttributeData | null;
      setup_time: number;
      vin: string;
      model_title: string;
      characteristics: Array<App.Enums.VehicleConsultations.VehicleCharacteristicEnum>;
    };
    export type VehicleSearchData = {
      brand: App.Enums.VehicleConsultations.VehicleBrandEnum;
      filter: Array<App.Enums.VehicleConsultations.VehicleFilterEnum>;
      series: Array<App.Enums.VehicleConsultations.VehicleSeriesEnum>;
      vehicle_to_compare_with: string | null;
      booking_type: App.Enums.VehicleConsultations.BookingTypeEnum;
    };
  }
  export namespace App.Enums {
    export enum AttachmentMimeTypeEnum {
      "IMAGE_PNG" = "image/png",
      "IMAGE_JPG" = "image/jpeg",
      "IMAGE_SVG" = "image/svg+xml",
      "IMAGE_WEBP" = "image/webp",
      "APPLICATION_PDF" = "application/pdf",
    }
    export enum AttachmentTypeEnum {
      "DIRECTORY" = "directory",
      "FILE" = "file",
    }
    export enum CountryCodeISOAlpha3Enum {
      "ABW" = "ABW",
      "AFG" = "AFG",
      "AGO" = "AGO",
      "AIA" = "AIA",
      "ALA" = "ALA",
      "ALB" = "ALB",
      "AND" = "AND",
      "ARE" = "ARE",
      "ARG" = "ARG",
      "ARM" = "ARM",
      "ASM" = "ASM",
      "ATA" = "ATA",
      "ATF" = "ATF",
      "ATG" = "ATG",
      "AUS" = "AUS",
      "AUT" = "AUT",
      "AZE" = "AZE",
      "BDI" = "BDI",
      "BEL" = "BEL",
      "BEN" = "BEN",
      "BES" = "BES",
      "BFA" = "BFA",
      "BGD" = "BGD",
      "BGR" = "BGR",
      "BHR" = "BHR",
      "BHS" = "BHS",
      "BIH" = "BIH",
      "BLM" = "BLM",
      "BLR" = "BLR",
      "BLZ" = "BLZ",
      "BMU" = "BMU",
      "BOL" = "BOL",
      "BRA" = "BRA",
      "BRB" = "BRB",
      "BRN" = "BRN",
      "BTN" = "BTN",
      "BVT" = "BVT",
      "BWA" = "BWA",
      "CAF" = "CAF",
      "CAN" = "CAN",
      "CCK" = "CCK",
      "CHE" = "CHE",
      "CHL" = "CHL",
      "CHN" = "CHN",
      "CIV" = "CIV",
      "CMR" = "CMR",
      "COD" = "COD",
      "COG" = "COG",
      "COK" = "COK",
      "COL" = "COL",
      "COM" = "COM",
      "CPV" = "CPV",
      "CRI" = "CRI",
      "CUB" = "CUB",
      "CUW" = "CUW",
      "CXR" = "CXR",
      "CYM" = "CYM",
      "CYP" = "CYP",
      "CZE" = "CZE",
      "DEU" = "DEU",
      "DJI" = "DJI",
      "DMA" = "DMA",
      "DNK" = "DNK",
      "DOM" = "DOM",
      "DZA" = "DZA",
      "ECU" = "ECU",
      "EGY" = "EGY",
      "ERI" = "ERI",
      "ESH" = "ESH",
      "ESP" = "ESP",
      "EST" = "EST",
      "ETH" = "ETH",
      "FIN" = "FIN",
      "FJI" = "FJI",
      "FLK" = "FLK",
      "FRA" = "FRA",
      "FRO" = "FRO",
      "FSM" = "FSM",
      "GAB" = "GAB",
      "GBR" = "GBR",
      "GEO" = "GEO",
      "GGY" = "GGY",
      "GHA" = "GHA",
      "GIB" = "GIB",
      "GIN" = "GIN",
      "GLP" = "GLP",
      "GMB" = "GMB",
      "GNB" = "GNB",
      "GNQ" = "GNQ",
      "GRC" = "GRC",
      "GRD" = "GRD",
      "GRL" = "GRL",
      "GTM" = "GTM",
      "GUF" = "GUF",
      "GUM" = "GUM",
      "GUY" = "GUY",
      "HKG" = "HKG",
      "HMD" = "HMD",
      "HND" = "HND",
      "HRV" = "HRV",
      "HTI" = "HTI",
      "HUN" = "HUN",
      "IDN" = "IDN",
      "IMN" = "IMN",
      "IND" = "IND",
      "IOT" = "IOT",
      "IRL" = "IRL",
      "IRN" = "IRN",
      "IRQ" = "IRQ",
      "ISL" = "ISL",
      "ISR" = "ISR",
      "ITA" = "ITA",
      "JAM" = "JAM",
      "JEY" = "JEY",
      "JOR" = "JOR",
      "JPN" = "JPN",
      "KAZ" = "KAZ",
      "KEN" = "KEN",
      "KGZ" = "KGZ",
      "KHM" = "KHM",
      "KIR" = "KIR",
      "KNA" = "KNA",
      "KOR" = "KOR",
      "KWT" = "KWT",
      "LAO" = "LAO",
      "LBN" = "LBN",
      "LBR" = "LBR",
      "LBY" = "LBY",
      "LCA" = "LCA",
      "LIE" = "LIE",
      "LKA" = "LKA",
      "LSO" = "LSO",
      "LTU" = "LTU",
      "LUX" = "LUX",
      "LVA" = "LVA",
      "MAC" = "MAC",
      "MAF" = "MAF",
      "MAR" = "MAR",
      "MCO" = "MCO",
      "MDA" = "MDA",
      "MDG" = "MDG",
      "MDV" = "MDV",
      "MEX" = "MEX",
      "MHL" = "MHL",
      "MKD" = "MKD",
      "MLI" = "MLI",
      "MLT" = "MLT",
      "MMR" = "MMR",
      "MNE" = "MNE",
      "MNG" = "MNG",
      "MNP" = "MNP",
      "MOZ" = "MOZ",
      "MRT" = "MRT",
      "MSR" = "MSR",
      "MTQ" = "MTQ",
      "MUS" = "MUS",
      "MWI" = "MWI",
      "MYS" = "MYS",
      "MYT" = "MYT",
      "NAM" = "NAM",
      "NCL" = "NCL",
      "NER" = "NER",
      "NFK" = "NFK",
      "NGA" = "NGA",
      "NIC" = "NIC",
      "NIU" = "NIU",
      "NLD" = "NLD",
      "NOR" = "NOR",
      "NPL" = "NPL",
      "NRU" = "NRU",
      "NZL" = "NZL",
      "OMN" = "OMN",
      "PAK" = "PAK",
      "PAN" = "PAN",
      "PCN" = "PCN",
      "PER" = "PER",
      "PHL" = "PHL",
      "PLW" = "PLW",
      "PNG" = "PNG",
      "POL" = "POL",
      "PRI" = "PRI",
      "PRK" = "PRK",
      "PRT" = "PRT",
      "PRY" = "PRY",
      "PSE" = "PSE",
      "PYF" = "PYF",
      "QAT" = "QAT",
      "REU" = "REU",
      "ROU" = "ROU",
      "RUS" = "RUS",
      "RWA" = "RWA",
      "SAU" = "SAU",
      "SDN" = "SDN",
      "SEN" = "SEN",
      "SGP" = "SGP",
      "SGS" = "SGS",
      "SHN" = "SHN",
      "SJM" = "SJM",
      "SLB" = "SLB",
      "SLE" = "SLE",
      "SLV" = "SLV",
      "SMR" = "SMR",
      "SOM" = "SOM",
      "SPM" = "SPM",
      "SRB" = "SRB",
      "SSD" = "SSD",
      "STP" = "STP",
      "SUR" = "SUR",
      "SVK" = "SVK",
      "SVN" = "SVN",
      "SWE" = "SWE",
      "SWZ" = "SWZ",
      "SXM" = "SXM",
      "SYC" = "SYC",
      "SYR" = "SYR",
      "TCA" = "TCA",
      "TCD" = "TCD",
      "TGO" = "TGO",
      "THA" = "THA",
      "TJK" = "TJK",
      "TKL" = "TKL",
      "TKM" = "TKM",
      "TLS" = "TLS",
      "TON" = "TON",
      "TTO" = "TTO",
      "TUN" = "TUN",
      "TUR" = "TUR",
      "TUV" = "TUV",
      "TWN" = "TWN",
      "TZA" = "TZA",
      "UGA" = "UGA",
      "UKR" = "UKR",
      "UMI" = "UMI",
      "URY" = "URY",
      "USA" = "USA",
      "UZB" = "UZB",
      "VAT" = "VAT",
      "VCT" = "VCT",
      "VEN" = "VEN",
      "VGB" = "VGB",
      "VIR" = "VIR",
      "VNM" = "VNM",
      "VUT" = "VUT",
      "WLF" = "WLF",
      "WSM" = "WSM",
      "YEM" = "YEM",
      "ZAF" = "ZAF",
      "ZMB" = "ZMB",
      "ZWE" = "ZWE",
    }
    export enum ModelStateEnum {
      "DRAFT" = "draft",
      "PUBLISHED" = "published",
      "ARCHIVED" = "archived",
      "LOCKED" = "locked",
    }
    export enum PublicErrorCodeEnum {
      "AUTH_GENERAL_EXCEPTION" = "auth_general_exception",
      "ACCOUNTING_GENERAL_DOCUMENT_STATE_EXCEPTION" = "accounting_general_document_state_exception",
      "ACCOUNTING_GENERAL_DOCUMENT_TYPE_EXCEPTION" = "accounting_general_document_type_exception",
      "ATTACHMENT_GENERAL_STORAGE_EXCEPTION" = "attachment_general_storage_exception",
      "CART_GENERAL_EXCEPTION" = "cart_general_exception",
      "CART_EXPIRED_EXCEPTION" = "cart_expired_exception",
      "CART_BOUGHT_EXCEPTION" = "cart_bought_exception",
      "CART_ITEM_GENERAL_EXCEPTION" = "cart_item_general_exception",
      "PAYMENT_PROVIDER_SELECTION_EXCEPTION" = "payment_provider_selection_exception",
      "PAYMENT_GENERAL_EXCEPTION" = "payment_general_exception",
      "PAYMENT_BMW_PAY_EXCEPTION" = "payment_bmw_pay_exception",
      "SEARCH_FILTER_PROPERTY_EXCEPTION" = "search_filter_property_exception",
      "ORDER_EMAIL_EXCEPTION" = "order_general_email_exception",
      "ORDER_GENERAL_EXCEPTION" = "order_general_exception",
      "SALES_CHANNEL_GENERAL_EXCEPTION" = "sales_channel_general_exception",
      "GENERAL_EXCEPTION" = "general_exception",
      "ACCOUNTING_GENERAL_DAY_END_CLOSING_STATE_EXCEPTION" = "general_day_end_closing_state_exception",
      "VEHICLE_CONSULTATION_GENERAL_EXCEPTION" = "general_vehicle_consultation_exception",
      "TICKET_VALIDATION_GENERAL_EXCEPTION" = "ticket_validation_general_exception",
      "WALLET_TICKET_GENERAL_EXCEPTION" = "wallet_ticket_general_exception",
      "GENERAL_VALIDATION_EXCEPTION" = "general_validation_exception",
    }
    export enum TableConfigColumnTypeEnum {
      "STRING" = "string",
      "BOOL" = "bool",
      "INT" = "int",
      "MONEY" = "money",
      "FLOAT" = "float",
      "ARRAY" = "array",
      "DATE" = "date",
      "CARBON_IMMUTABLE" = "carbon_immutable",
      "ORDER_ACTION_RESOURCE" = "order_action_resource",
      "ATTACHMENT_TYPE_ENUM" = "attachment_type_enum",
      "ATTACHMENT_MIME_TYPE_ENUM" = "attachment_mime_type_enum",
      "BOOKING_QUESTION_RESPONSE_TYPE_ENUM" = "booking_question_response_type_enum",
      "COUNTRY_CODE_ISO_ALPHA_3_ENUM" = "country_code_iso_alpha_3_enum",
      "DASHBOARD_CATEGORY" = "dashboard_category",
      "DOCUMENT_STATE_ENUM" = "document_state_enum",
      "DOCUMENT_TYPE_ENUM" = "document_type_enum",
      "EMAIL_TYPE_ENUM" = "email_type_enum",
      "GUEST_SALUTATION_ENUM" = "guest_salutation_enum",
      "GUEST_TITLE_ENUM" = "guest_title_enum",
      "PAYMENT_SERVICE_PROVIDER_ENUM" = "payment_service_provider_enum",
      "PAYMENT_STATE_ENUM" = "payment_state_enum",
      "SLOT_LANGUAGE_ENUM" = "slot_language_enum",
      "SLOT_TYPE_ENUM" = "slot_type_enum",
      "ORDER_EDIT_STATE_ENUM" = "order_edit_state_enum",
      "SALES_CHANNEL_ENUM" = "sales_channel_enum",
      "ORDER_CUSTOMER_SERVICE_STATE_ENUM" = "order_customer_service_state_enum",
      "ORDER_CUSTOMER_SERVICE_PROPOSAL_PAYMENT_TYPE_ENUM" = "order_customer_service_proposal_payment_type_enum",
      "GUEST_LOCALE_ENUM" = "guest_locale_enum",
      "MODEL_STATE_ENUM" = "model_state_enum",
      "MODEL_STATE_STATE_MACHINE" = "model_state_state_machine",
      "ORDER_STATE_ENUM" = "order_state_enum",
      "DAY_END_CLOSING_STATE_MACHINE" = "day_end_closing_state_machine",
      "DERIVED_ORDER_TYPE_ENUM" = "derived_order_type_enum",
      "DAY_END_CLOSING_STATE_ENUM" = "day_end_closing_state_enum",
      "DAY_END_CLOSING_DIFFERENCE_REASON_ENUM" = "day_end_closing_difference_reason_enum",
      "MULTI_LANGUAGE_ATTRIBUTE_DATA" = "multi_language_attribute_data",
      "UNION_TYPE" = "union_type",
      "RELATION_UUID_ATTRIBUTE_DATA" = "relation_uuid_attribute_data",
      "RELATION_TITLE_ATTRIBUTE_DATA" = "relation_title_attribute_data",
      "RELATION_BOOKING_QUESTION_ANSWER_OPTION_RESOURCE" = "relation_booking_question_answer_option_resource",
      "RELATION_LOCATION_RESOURCE" = "relation_location_resource",
      "RELATION_DISCOUNT_REASON_RESOURCE" = "relation_discount_reason_resource",
      "RELATION_SEGMENT_RESOURCE" = "relation_segment_resource",
      "RELATION_BOOKING_QUESTION_RESOURCE" = "relation_booking_question_resource",
      "RELATION_ATTACHMENTS_RESOURCE" = "relation_attachments_resource",
      "RELATION_CANCELLATION_REASON" = "relation_cancellation_reason",
      "RELATION_ARTICLE_RESOURCE" = "relation_article_resource",
      "RELATION_ARTICLE_PRICE_RESOURCE" = "relation_article_price_resource",
      "RELATION_GUIDE_RESOURCE" = "relation_guide_resource",
      "RELATION_AUDIENCE_RESOURCE" = "relation_audience_resource",
      "RELATION_LANGUAGE_SKILLS_RESOURCE" = "relation_language_skills_resource",
      "RELATION_GUEST_RESOURCE" = "relation_guest_resource",
      "RELATION_ORDER_RESOURCE" = "relation_order_resource",
      "RELATION_ORDER_ITEM_RESOURCE" = "relation_order_item_resource",
      "RELATION_ORDER_BOOKING_QUESTION_RESOURCE" = "relation_order_booking_question_resource",
      "RELATION_AGGREGATED_ORDER_ITEM_RESOURCE" = "relation_aggregated_order_item_resource",
      "RELATION_PAYMENT_RESOURCE" = "relation_payment_resource",
      "RELATION_INVOICE_RESOURCE" = "relation_invoice_resource",
      "RELATION_VENUE_RESOURCE" = "relation_venue_resource",
      "RELATION_SETTLEMENT_ACCOUNT" = "relation_settlement_account",
      "RELATION_EMAIL_RESOURCE" = "relation_email_resource",
      "VERSIONED_GUEST_ID" = "versioned_guest_id",
      "FK_LOCATION_ID" = "fk_location_id",
      "FK_SETTLEMENT_ACCOUNT_ID" = "fk_settlement_account_id",
      "FK_ARTICLE_ID" = "fk_article_id",
      "FK_VENUE_ID" = "fk_venue_id",
      "FK_GUEST_ID" = "fk_guest_id",
      "FK_BOOKING_SLOT_ID" = "fk_booking_slot_id",
      "FK_VEHICLE_ID" = "fk_vehicle_id",
      "FK_GUIDE_ID" = "fk_guide_id",
      "FK_COUNSELLING_DESK_ID" = "fk_counselling_desk_id",
      "VC_VEHICLE_CONSTRUCTION_TYPE_RESOURCE" = "vc_vehicle_construction_type_resource",
      "VC_VEHICLE_AVAILABILITY_RESOURCE" = "vc_vehicle_availability_resource",
      "VC_COUNSELLING_DESK_AVAILABILITY_RESOURCE" = "vc_counselling_desk_availability_resource",
      "VC_COUNSELLING_DESK_CHARACTERISTIC_ENUM" = "vc_counselling_desk_characteristic_enum",
      "VC_GENIUS_AVAILABILITY_TYPE_ENUM" = "vc_genius_availability_type_enum",
      "VC_GENIUS_QUALIFICATION_ENUM" = "vc_genius_qualification_enum",
      "VC_VEHICLE_AVAILABILITY_TYPE_ENUM" = "vc_vehicle_availability_type_enum",
      "VC_VEHICLE_BRAND_ENUM" = "vc_vehicle_brand_enum",
      "VC_VEHICLE_CONSTRUCTION_TYPE_ENUM" = "vc_vehicle_construction_type_enum",
      "VC_VEHICLE_FUEL_TYPE_ENUM" = "vc_vehicle_fuel_type_enum",
      "VC_VEHICLE_ICON_TYPE_ENUM" = "vc_vehicle_icon_type_enum",
      "VC_VEHICLE_SERIES_ENUM" = "vc_vehicle_series_enum",
      "VC_BOOKING_STATE_ENUM" = "vc_booking_state_enum",
      "VC_BOOKING_TYPE_ENUM" = "vc_booking_type_enum",
      "VC_RELATION_COUNSELLING_DESK_RESOURCE" = "vc_relation_counselling_desk_resource",
      "VC_RELATION_VEHICLE_RESOURCE" = "vc_relation_vehicle_resource",
    }
    export enum TableRelativeDateFilterEnum {
      "TODAY" = "today",
      "TOMORROW" = "tomorrow",
      "YESTERDAY" = "yesterday",
      "LAST_WEEK" = "last_week",
      "THIS_WEEK" = "this_week",
      "NEXT_WEEK" = "next_week",
      "THIS_MONTH" = "this_month",
      "NEXT_MONTH" = "next_month",
      "THIS_YEAR" = "this_year",
    }
  }
  export namespace App.Enums.Accounting {
    export enum DocumentStateEnum {
      "PENDING" = "pending",
      "CREATED" = "created",
      "FAILED" = "failed",
    }
    export enum DocumentTypeEnum {
      "DH_INVOICE" = "dh_invoice",
      "DH_REFUND" = "dh_refund",
      "ECOS_INVOICE" = "ecos_invoice",
      "ECOS_REFUND" = "ecos_refund",
    }
    export enum SafebagStateEnum {
      "PENDING_TRANSFER_TO_PROSEGUR" = "pending_transfer_to_prosegur",
      "AWAITING_SAFEBAG_COLLECTION" = "awaiting_safebag_collection",
      "AWAITING_SAFEBAG_COUNTING" = "awaiting_safebag_counting",
      "AWAITING_SAFEBAG_PAYOUT" = "awaiting_safebag_payout",
      "COMPLETED" = "completed",
    }
  }
  export namespace App.Enums.Administrations {
    export enum EmailPlaceholderEnum {
      "SALUTATION" = "salutation",
      "FIRST_NAME" = "first_name",
      "LAST_NAME" = "last_name",
      "QUOTE_NUMBER" = "quote_number",
      "QUOTE_VALIDITY_DATE" = "quote_validity_date",
      "ADDITIONAL_TEXT" = "additional_text",
      "BOOKING_NUMBER" = "booking_number",
      "BOOKING_EDIT_LINK" = "booking_edit_link",
      "BOOKINGS_TABLE" = "bookings_table",
      "NEW_BOOKINGS_TABLE" = "new_bookings_table",
      "OLD_BOOKINGS_TABLE" = "old_bookings_table",
      "CANCELLED_BOOKINGS_TABLE" = "cancelled_bookings_table",
      "PERSONAL_EMAIL_SIGNATURE" = "personal_email_signature",
      "CUSTOMER_SERVICE_PROPOSAL_PAYMENT_TYPE" = "customer_service_proposal_payment_type",
      "OFFER_PAYMENT_LINK" = "offer_payment_link",
      "RESERVATION_PICKUP_CODE" = "reservation_pickup_code",
      "RESERVATION_PICKUP_CODE_TEXT" = "reservation_pickup_code_text",
      "RESERVATION_PICKUP_CODE_QR" = "reservation_pickup_code_qr",
      "NEW_LINE" = "new_line",
      "ACCOUNTING_DOCUMENT_LINK" = "accounting_document_link",
    }
    export enum EmailTypeEnum {
      "QUOTE" = "quote",
      "QUOTE_REMINDER" = "quote_reminder",
      "BOOKING_CONFIRMATION" = "booking_confirmation",
      "REBOOKING_CONFIRMATION" = "rebooking_confirmation",
      "CANCELLATION_CONFIRMATION" = "cancellation_confirmation",
      "CANCELLATION_MESSAGE" = "cancellation_message",
      "TICKET_MESSAGE" = "ticket_message",
      "PAY_PER_PAYMENT_LINK" = "pay_per_payment_link",
      "RESERVATION_PICKUP" = "reservation_pickup",
    }
  }
  export namespace App.Enums.Bookings {
    export enum DashboardCategoryEnum {
      "MUSEUM" = "museum",
      "OTHER" = "other",
      "VEHICLE_CONSULTATION" = "vehicle_consultation",
    }
    export enum SlotLanguageEnum {
      "ENGLISH" = "english",
      "GERMAN" = "german",
    }
    export enum SlotTypeEnum {
      "UNKNOWN" = "unknown",
      "PUBLIC_OFFER" = "public_offer",
      "EXCLUSIVE_OFFER" = "exclusive_offer",
      "VEHICLE_CONSULTATION_OFFER" = "vehicle_consultation_offer",
    }
  }
  export namespace App.Enums.Ecos {
    export enum EcosDayEndClosingDifferenceReasonEnum {
      "UNDER_PAYMENT" = "under_payment",
      "OVER_PAYMENT" = "over_payment",
      "MISCOUNTED" = "miscounted",
      "FAKE_MONEY" = "fake_money",
      "UNDER_CHANGE" = "under_change",
      "OVER_CHANGE" = "over_change",
    }
    export enum EcosDayEndClosingStateEnum {
      "NEW" = "new",
      "STARTED" = "started",
      "REQUESTING_SECOND_CONFIRMATION" = "requesting_second_confirmation",
      "WAITING_FOR_SECOND_CONFIRMATION" = "waiting_for_second_confirmation",
      "COMPLETED" = "completed",
      "COMPLETED_AUTOMATICALLY" = "completed_automatically",
    }
  }
  export namespace App.Enums.Guests {
    export enum GuestLocaleEnum {
      "DE" = "de",
      "EN" = "en",
    }
    export enum GuestSalutationEnum {
      "UNKNOWN" = "unknown",
      "FEMALE" = "female",
      "MALE" = "male",
      "DIVERS" = "diverse",
    }
    export enum GuestTitleEnum {
      "PROFESSOR" = "professor",
      "DOCTOR" = "doctor",
      "PROFESSOR_DOCTOR" = "professor_doctor",
    }
  }
  export namespace App.Enums.Orders {
    export enum DerivedOrderTypeEnum {
      "RESERVATION" = "reservation",
      "RESERVATION_ARCHIVED" = "reservation_archived",
      "BOOKING" = "booking",
      "CANCELLATION" = "cancellation",
    }
    export enum OrderActionEnum {
      "PAYMENT_SEND_PAYMENT_LINK" = "payment_send_payment_link",
      "PAYMENT_PAYMENT_LINK_CANCELLABLE" = "payment_payment_link_cancellable",
      "PAYMENT_PAYMENT_ONSITE" = "payment_payment_on_site",
      "PAYMENT_PAYMENT_ONSITE_CANCELLABLE" = "payment_payment_onsite_cancellable",
      "PAYMENT_PAYMENT_VIA_COST_CENTER" = "payment_payment_via_cost_center",
      "PAYMENT_PAYMENT_VIA_INVOICE" = "payment_payment_via_invoice",
      "PAYMENT_PAYMENT_FREE_TICKETS" = "payment_payment_free_tickets",
      "BOOKING_UPDATE" = "booking_update",
      "BOOKING_CANCEL" = "booking_cancel",
      "BOOKING_DELETE" = "booking_delete",
      "BOOKING_UPDATE_GUEST_VERSION" = "booking_update_guest_version",
      "EMAIL_OFFER_EMAIL" = "email_offer_email",
      "EMAIL_RESEND_TICKET_EMAIL" = "email_resend_ticket_email",
      "EMAIL_RESEND_BOOKING_CONFIRMATION_EMAIL" = "email_resend_booking_confirmation_email",
      "EMAIL_BOOKING_UPDATED_CONFIRMATION_EMAIL" = "email_booking_updated_confirmation_email",
      "EMAIL_CANCELLATION_CONFIRMATION_EMAIL" = "email_cancellation_confirmation_email",
      "EMAIL_CANCELLATION_NOTIFICATION_EMAIL" = "email_cancellation_notification_email",
      "EMAIL_QUOTE_REMINDER_EMAIL" = "email_quote_reminder_email",
      "ADD_SLOT_TYPE_PUBLIC_OFFER" = "add_slot_type_public_offer",
      "ADD_SLOT_TYPE_EXCLUSIVE_OFFER" = "add_slot_type_exclusive_offer",
      "ADD_SLOT_TYPE_VEHICLE_CONSULTATION_OFFER" = "add_slot_type_vehicle_consultation_offer",
    }
    export enum OrderCustomerServiceProposalPaymentTypeEnum {
      "PAYMENT_LINK" = "payment_link",
      "COST_CENTER" = "cost_center",
      "PICKUP" = "pick_up",
      "INVOICE" = "invoice",
    }
    export enum OrderCustomerServiceStateEnum {
      "RECONFIRMATION_SENT" = "reconfirmation_sent",
      "RECONFIRMATION_RECEIVED" = "reconfirmation_received",
      "RECONFIRMATION_PENDING" = "reconfirmation_pending",
      "DEADLINE_EXTENDED" = "deadline_extended",
      "RESERVED" = "reserved",
      "REMINDER_SENT" = "reminder_sent",
      "BOOKED_IN_PERSON_PAYMENT" = "booked_in_person_payment",
      "BOOKED_EVENT_FORUM" = "booked_event_forum",
      "BOOKED_INVOICE" = "booked_invoice",
      "BOOKED_PAYMENT_LINK" = "booked_payment_link",
      "BOOKED_COST_CENTER" = "booked_cost_center",
    }
    export enum OrderEditStateEnum {
      "EVERYTHING" = "everything",
      "CUSTOMER_SERVICE_AND_ITEM_DECREMENTS" = "customer_service_and_item_decrements",
      "CUSTOMER_SERVICE" = "customer_service",
      "READONLY" = "readonly",
    }
    export enum OrderItemStateEnum {
      "CREATED" = "created",
      "PAYED" = "payed",
      "REFUND_PENDING" = "refund_pending",
      "REFUNDED" = "refunded",
      "EXPIRED" = "expired",
    }
    export enum OrderReservationStateEnum {
      "COLLECTED" = "collected",
      "EXPIRED" = "expired",
      "PENDING" = "pending",
    }
    export enum OrderStateEnum {
      "CREATED" = "created",
      "RESERVED" = "reserved",
      "RESERVATION_COLLECTED" = "reservation_collected",
      "PAYMENT_PENDING" = "payment_pending",
      "PAYMENT_STARTED" = "payment_started",
      "PAYMENT_COMPLETED" = "payment_completed",
      "PARTIAL_REFUND_PENDING" = "partial_refund_pending",
      "PARTIAL_REFUND_STARTED" = "partial_refund_started",
      "PARTIAL_REFUND_COMPLETED" = "partial_refund_completed",
      "REFUND_PENDING" = "refund_pending",
      "REFUND_STARTED" = "refund_started",
      "REFUND_COMPLETED" = "refund_completed",
      "CANCELLED" = "cancelled",
      "DISPUTED" = "disputed",
      "EXPIRED" = "expired",
      "EXPIRED_NO_PAYMENT_TRIED" = "expired_no_payment_tried",
      "RESERVATION_ARCHIVED" = "reservation_archived",
    }
  }
  export namespace App.Enums.Orders.Payment {
    export enum PaymentMethodTypeEnum {
      "CREDIT_CARD" = "CreditCard",
      "PAYPAL" = "PayPal",
      "SOFORT" = "Sofort",
      "CASH" = "Cash",
      "COST_CENTER" = "CostCenter",
      "FREE" = "free",
      "INVOICE" = "invoice",
      "APPLE_PAY" = "ApplePay",
    }
    export enum PaymentProviderEnum {
      "ADYEN" = "ADYEN",
      "PAYPAL" = "PAYPAL",
      "COLLECTOR" = "collector",
      "COST_CENTER" = "cost_center",
      "INVOICE" = "invoice",
      "MOCK" = "MOCK",
      "SELECTION_PENDING" = "selection_pending",
      "CASH" = "cash",
      "FREE" = "free",
    }
    export enum PaymentServiceProviderEnum {
      "BMW_PAY" = "bmw_pay",
      "ADYEN" = "adyen",
      "COLLECTOR" = "collector",
      "COST_CENTER" = "cost_center",
      "INVOICE" = "invoice",
      "SELECTION_PENDING" = "selection_pending",
      "CASH" = "cash",
      "IP_PICK_UP" = "ip_pick_up",
      "FREE" = "free",
    }
    export enum PaymentStateEnum {
      "CREATED" = "created",
      "PAYMENT_PENDING" = "pending",
      "PAYMENT_STARTED" = "started",
      "PAYMENT_COMPLETED" = "completed",
      "CANCELLED" = "cancelled",
      "EXPIRED" = "expired",
      "EXPIRED_NOT_STARTED" = "expired_not_started",
      "DISPUTED" = "disputed",
      "FAILED" = "failed",
    }
    export enum PaymentTypeEnum {
      "PAYMENT" = "payment",
      "REFUND" = "refund",
    }
    export enum RefundTypeEnum {
      "PARTIAL" = "partial",
      "FULL" = "full",
    }
  }
  export namespace App.Enums.Orders.Ticket {
    export enum TicketStateEnum {
      "PENDING" = "pending",
      "ACTIVE" = "active",
      "CANCELLED" = "cancelled",
    }
    export enum TicketTransactionSourceEnum {
      "MUSEUM_TURNSTILE_1" = "museum_turnstile_1",
      "MUSEUM_TURNSTILE_2" = "museum_turnstile_2",
      "MUSEUM_TURNSTILE_3" = "museum_turnstile_3",
      "MUSEUM_TURNSTILE_4" = "museum_turnstile_4",
      "TESTING" = "testing",
    }
    export enum TicketTransactionTypeEnum {
      "KEEP_ALIVE" = "keep_alive",
      "VALIDATION" = "validation",
      "VALIDATION_OFFLINE" = "validation_offline",
    }
    export enum TicketValidationStateEnum {
      "VALID" = "valid",
      "INVALID" = "invalid",
    }
    export enum TicketValidationTypeEnum {
      "ONLINE_VALIDATION" = "online_validation",
      "ONLINE_SIGNATURE_ONLY_VALIDATION" = "online_signature_validation",
      "OFFLINE_VALIDATION" = "offline_validation",
    }
  }
  export namespace App.Enums.Reporting {
    export enum ReportingDatasetEnum {
      "ORDER_ITEM" = "order_item",
    }
    export enum ReportingFilterOperatorTypeEnum {
      "EQUALS" = "equals",
      "NOT_EQUALS" = "not_equals",
      "IN" = "in",
      "NOT_IN" = "not_in",
      "INCLUSIVE_BEFORE" = "inclusive_before",
      "INCLUSIVE_AFTER" = "inclusive_after",
      "INCLUSIVE_IN_BETWEEN" = "inclusive_in_between",
    }
    export enum ReportingTemplateTypeEnum {
      "FINANCE_ORDER_ITEM" = "finance_order_item",
      "FINANCE_COST_CENTER" = "finance_cost_center",
      "OPERATIONS_BOOKINGS" = "operations_bookings",
      "OPERATIONS_SLOTS" = "operations_slots",
    }
  }
  export namespace App.Enums.Settings {
    export enum BookingQuestionResponseTypeEnum {
      "DROPDOWN" = "dropdown",
      "RADIO" = "radio",
      "FREE_TEXT" = "free_text",
    }
    export enum SalesChannelEnum {
      "TICKET_SHOP" = "ticket_shop",
      "CUSTOMER_SERVICE" = "customer_service",
      "COLLECTOR_GUIDE_CLASSIC" = "collector_guide_classic",
      "COLLECTOR_FACTORY" = "collector_factory",
      "COLLECTOR_MUSEUM" = "collector_museum",
      "COLLECTOR_TOUR" = "collector_tour",
      "ONSITE_CASH_DESK" = "onsite_cash_desk",
      "ONSITE_SELF_SERVICE" = "onsite_self_service",
    }
  }
  export namespace App.Enums.Settings.Article {
    export enum ArticlePriceTypeEnum {
      "PAID" = "paid",
      "DISCOUNTED" = "discounted",
      "FREE" = "free",
    }
    export enum OnlineShopFilterEnum {
      "MUSEUM_ADMISSION" = "museum_admission",
      "OPEN_OFFER" = "open_offer",
      "THE_CAMPUS" = "the_campus",
      "VEHICLE_CONSULTATION" = "vehicle_consultation",
    }
    export enum TimeBasedDiscountTypesEnum {
      "ONE_HOUR" = "one_hour",
      "TWO_HOURS" = "two_hours",
      "THREE_HOURS" = "three_hours",
      "FOUR_HOURS" = "four_hours",
    }
  }
  export namespace App.Enums.VehicleConsultations {
    export enum BookingStateEnum {
      "ACTIVE" = "active",
      "INACTIVE" = "inactive",
    }
    export enum BookingTypeEnum {
      "ON_SITE_STANDARD" = "on_site_standard",
      "ON_SITE_INDIVIDUAL" = "on_site_individual",
      "ONLINE_STANDARD" = "online_standard",
      "ONLINE_INDIVIDUAL" = "online_individual",
    }
    export enum CounsellingDeskCharacteristicEnum {
      "ONSITE" = "on_site",
      "DIGITAL" = "digital",
      "INDIVIDUAL" = "individual",
    }
    export enum GeniusAvailabilityImportStatusTypeEnum {
      "SUCCESSFUL" = "successful",
      "SUCCESSFUL_WITH_WARNINGS" = "successful_with_warnings",
      "FAILED" = "failed",
    }
    export enum GeniusAvailabilityTypeEnum {
      "AVAILABLE" = "available",
      "NOT_AVAILABLE" = "not_available",
      "SPARE" = "spare",
    }
    export enum GeniusQualificationEnum {
      "STANDARD" = "standard",
      "DIGITAL" = "digital",
      "INDIVIDUAL" = "individual",
    }
    export enum VehicleAvailabilityTypeEnum {
      "ON_SITE" = "on_site",
      "ONLINE" = "online",
    }
    export enum VehicleBrandEnum {
      "BMW" = "bmw",
      "MINI" = "mini",
    }
    export enum VehicleCharacteristicEnum {
      "ON_SITE_INDIVIDUAL" = "on_site_individual",
      "ONLINE_INDIVIDUAL" = "online_individual",
    }
    export enum VehicleConstructionTypeEnum {
      "ON_SITE_AVAILABLE" = "on_site_available",
      "BMW_M" = "bmw_m",
      "BMW_I" = "bmw_i",
      "BMW_CABRIO" = "bmw_cabrio",
      "BMW_TOURING" = "bmw_touring",
      "BMW_LIMOUSINE" = "bmw_limousine",
      "BMW_COUPE" = "bmw_coupe",
      "BMW_GRAN_COUPE" = "bmw_gran_coupe",
      "MINI_ELECTRIC" = "mini_electric",
      "MINI_PETROL" = "mini_petrol",
      "MINI_JOHN_COOPER_WORKS" = "mini_john_cooper_works",
    }
    export enum VehicleFilterEnum {
      "ON_SITE_AVAILABLE" = "on_site_available",
      "BMW_M" = "bmw_m",
      "BMW_I" = "bmw_i",
      "BMW_CABRIO" = "bmw_cabrio",
      "BMW_TOURING" = "bmw_touring",
      "BMW_LIMOUSINE" = "bmw_limousine",
      "BMW_COUPE" = "bmw_coupe",
    }
    export enum VehicleFuelTypeEnum {
      "DIESEL" = "diesel",
      "GASOLINE" = "gasoline",
      "ALL_ELECTRIC" = "all_electric",
      "PLUG_IN_HYBRID" = "plug_in_hybrid",
    }
    export enum VehicleIconTypeEnum {
      "I_ICON" = "i_icon",
      "M_ICON" = "m_icon",
      "PLUG_IN_HYBRID_ICON" = "plug_in_hybrid_icon",
      "ALL_ELECTRIC_ICON" = "all_electric_icon",
    }
    export enum VehicleSeriesEnum {
      "BMW_SERIES_X" = "bmw_series_X",
      "BMW_SERIES_8" = "bmw_series_8",
      "BMW_SERIES_7" = "bmw_series_7",
      "BMW_SERIES_6" = "bmw_series_6",
      "BMW_SERIES_5" = "bmw_series_5",
      "BMW_SERIES_4" = "bmw_series_4",
      "BMW_SERIES_3" = "bmw_series_3",
      "BMW_SERIES_2" = "bmw_series_2",
      "BMW_SERIES_1" = "bmw_series_1",
      "BMW_SERIES_Z4" = "bmw_series_Z4",
      "MINI_COOPER_3_DOORS" = "mini_cooper_3_doors",
      "MINI_COOPER_5_DOORS" = "mini_cooper_5_doors",
      "MINI_CABRIO" = "mini_cabrio",
      "MINI_COUNTRYMAN" = "mini_countryman",
      "MINI_ACEMAN" = "mini_aceman",
    }
  }
  export namespace App.Http.Resources {
    export type AbstractBase = {};
    export type ErrorContainer = {
      error: App.Http.Resources.Error;
    };
    export type Error = {
      message: string;
      code: App.Enums.PublicErrorCodeEnum;
    };
  }
  export namespace App.Http.Resources.Dashboard {
    export type Attachment = {
      id: string;
      name: string;
      path: string;
      mime_type: string | null;
      type: App.Enums.AttachmentTypeEnum;
      created_at: string;
      updated_at: string;
      size: number | null;
      image_width: number | null;
      image_height: number | null;
      url: string | null;
    };
    export type Guest = {
      id: string;
      latest_version_id: string;
      state: App.Enums.ModelStateEnum;
      customer_id: string | null;
      salutation: App.Enums.Guests.GuestSalutationEnum;
      title: App.Enums.Guests.GuestTitleEnum | null;
      first_name: string;
      last_name: string;
      company: string | null;
      street: string | null;
      street_number: string | null;
      zip_code: string | null;
      city: string | null;
      country: string;
      phone_number: string | null;
      email: string | null;
      vat_id: string | null;
      ib_cost_center: string | null;
      ib_department: string | null;
      comment: string | null;
      created_at: string;
      updated_at: string;
      target_audience_ids?: Array<App.Data.Meta.UuidAttributeData>;
      target_audience_titles?: Array<App.Data.Meta.TitleAttributeData>;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
      locale: App.Enums.Guests.GuestLocaleEnum;
    };
  }
  export namespace App.Http.Resources.Dashboard.Accounting {
    export type Invoice = {
      id: string;
      external_document_id: string;
      state: App.Enums.Accounting.DocumentStateEnum;
      type: App.Enums.Accounting.DocumentTypeEnum;
      order?: App.Http.Resources.Dashboard.Orders.Order;
      payment?: App.Http.Resources.Dashboard.Orders.Payment.Payment;
      aggregated_ordered_items?: Array<App.Http.Resources.Dashboard.Orders.AggregatedOrderItem>;
      document_date: string | null;
      document_preview_url: string | null;
      total_net_price: number;
      total_tax_price: number;
      total_gross_price: number;
      created_at: string;
      updated_at: string;
    };
    export type Refund = {
      id: string;
      external_document_id: string;
      state: App.Enums.Accounting.DocumentStateEnum;
      type: App.Enums.Accounting.DocumentTypeEnum;
      invoice?: App.Http.Resources.Dashboard.Accounting.Invoice;
      order?: App.Http.Resources.Dashboard.Orders.Order;
      payment?: App.Http.Resources.Dashboard.Orders.Payment.Payment;
      aggregated_ordered_items?: Array<App.Http.Resources.Dashboard.Orders.AggregatedOrderItem>;
      document_date: string | null;
      document_preview_url: string | null;
      total_net_price: number;
      total_tax_price: number;
      total_gross_price: number;
      created_at: string;
      updated_at: string;
    };
  }
  export namespace App.Http.Resources.Dashboard.Accounting.DayEndClosing {
    export type DayEndClosing = {
      id: string;
      state: App.Enums.Ecos.EcosDayEndClosingStateEnum;
      operator: string;
      user_id: string | null;
      date: string;
      first_transaction_at: string;
      last_transaction_at: string;
      safebag_number: string | null;
      comment: string | null;
      counted_amount: number | null;
      day_end_difference_in_cent: number | null;
      reasons_for_difference: Array<App.Enums.Ecos.EcosDayEndClosingDifferenceReasonEnum> | null;
      confirmed_at: string | null;
      confirmed_by_name: string | null;
      confirmed_by_user_id: string | null;
      totals: App.Http.Resources.Dashboard.Accounting.DayEndClosing.DayEndClosingTotals;
      redirect_url: string | null;
      created_at: string;
      updated_at: string;
    };
    export type DayEndClosingTotals = {
      total_turnover: number;
      total_turnover_by_cash: number;
      total_turnover_by_card: number;
      total_orders: number;
      total_tickets: number;
      total_paid_turnover: number;
      total_paid_turnover_by_cash: number;
      total_paid_turnover_by_card: number;
      total_paid_orders: number;
      total_paid_tickets: number;
      total_refunded_turnover: number;
      total_refunded_turnover_by_cash: number;
      total_refunded_turnover_by_card: number;
      total_refunded_orders: number;
      total_refunded_tickets: number;
      total_turnover_by_reservations: number;
      total_turnover_by_reservation_cash: number;
      total_turnover_by_reservation_card: number;
      total_collected_reservations: number;
      total_collected_reserved_tickets: number;
    };
  }
  export namespace App.Http.Resources.Dashboard.Administrations {
    export type ContentElement = {
      id: string;
      state: App.Enums.ModelStateEnum;
      title: string;
      content: App.Data.Meta.MultilingualAttributeData;
      created_at: string;
      updated_at: string;
    };
    export type Email = {
      id: string;
      type: App.Enums.Administrations.EmailTypeEnum;
      title: string;
      subject: App.Data.Meta.MultilingualAttributeData;
      content: App.Data.Meta.MultilingualAttributeData;
      pre_content: App.Data.Meta.MultilingualAttributeData;
      post_content: App.Data.Meta.MultilingualAttributeData;
      created_at: string;
      updated_at: string;
      is_primary: boolean;
    };
    export type Page = {
      id: string;
      state: App.Enums.ModelStateEnum;
      slug: string;
      title: App.Data.Meta.MultilingualAttributeData;
      content: App.Data.Meta.MultilingualAttributeData;
      meta_description: App.Data.Meta.MultilingualAttributeData;
      sitemap_priority: number;
      created_at: string;
      updated_at: string;
      include_in_sitemap: boolean;
      index_follow: boolean;
    };
  }
  export namespace App.Http.Resources.Dashboard.Auth {
    export type AuthenticatedUser = {
      id: string;
      name: string;
      email: string;
      email_signature: string | null;
      email_verified_at: string | null;
      locale: string | null;
      roles?: Array<App.Http.Resources.Dashboard.Auth.Role>;
      permissions?: Array<string>;
      token: string | null;
    };
    export type Role = {
      id: string;
      key: string;
      guard_name: string;
    };
  }
  export namespace App.Http.Resources.Dashboard.Bookings {
    export type Slot = {
      id: string;
      numeric_id: number;
      state: App.Enums.ModelStateEnum;
      article_id: string;
      article?: App.Http.Resources.Dashboard.Settings.Articles.Article;
      all_states_article?: App.Http.Resources.Dashboard.Settings.Articles.Article;
      vehicle_id: string | null;
      vehicle?: App.Http.Resources.Dashboard.VehicleConsultations.Vehicles.Vehicle;
      all_states_vehicle?: App.Http.Resources.Dashboard.VehicleConsultations.Vehicles.Vehicle;
      comparison_vehicle_id: string | null;
      comparison_vehicle?: App.Http.Resources.Dashboard.VehicleConsultations.Vehicles.Vehicle;
      all_states_comparison_vehicle?: App.Http.Resources.Dashboard.VehicleConsultations.Vehicles.Vehicle;
      vehicle_consultation_booking_type: App.Enums.VehicleConsultations.BookingTypeEnum | null;
      type: App.Enums.Bookings.SlotTypeEnum;
      start_datetime: string;
      start_datetime_by_discount_type: string;
      end_datetime: string;
      venue_id: string;
      max_contingent: number;
      remaining_contingent: number;
      bookings_count?: number;
      language: App.Enums.Bookings.SlotLanguageEnum | null;
      dashboard_category: App.Enums.Bookings.DashboardCategoryEnum | null;
      hints: string | null;
      created_at: string;
      updated_at: string;
      resource_ids?: Array<App.Data.Meta.UuidAttributeData>;
      resource_titles?: Array<App.Data.Meta.TitleAttributeData>;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
      venue?: App.Http.Resources.Dashboard.Settings.Venue;
    };
  }
  export namespace App.Http.Resources.Dashboard.Meta {
    export type TableConfigColumn = {
      name: string;
      type: App.Enums.TableConfigColumnTypeEnum;
      filterable: boolean;
      quick_filter: boolean;
      required_filter: boolean;
      default_filter: Array<any> | string | null;
      sortable: boolean;
      initially_hidden: boolean;
      visible: boolean;
      filter_visible: boolean;
    };
    export type TableConfig = {
      id: string;
      url: string;
      columns: Array<App.Http.Resources.Dashboard.Meta.TableConfigColumn>;
      permissions: App.Http.Resources.Dashboard.Meta.TablePermission;
    };
    export type TablePermission = {
      create: string;
      update: string;
      delete: string;
      read: string;
    };
  }
  export namespace App.Http.Resources.Dashboard.Orders {
    export type AggregatedOrderItem = {
      aggregated_id: string;
      slot?: App.Http.Resources.Dashboard.Bookings.Slot;
      versioned_article?: App.Http.Resources.Dashboard.Settings.Articles.Article;
      versioned_article_price?: App.Http.Resources.Dashboard.Settings.Articles.ArticlePrice;
      discount_reason?: App.Http.Resources.Dashboard.Settings.DiscountReason;
      quantity: number;
      vc_booking?: App.Http.Resources.Dashboard.VehicleConsultations.Booking;
      vc_comparison_vehicle_id?: string;
    };
    export type OrderAction = {
      payment_send_payment_link: boolean;
      payment_payment_link_cancellable: boolean;
      payment_payment_on_site: boolean;
      payment_payment_onsite_cancellable: boolean;
      payment_payment_via_cost_center: boolean;
      payment_payment_via_invoice: boolean;
      payment_payment_free_tickets: boolean;
      booking_update: boolean;
      booking_cancel: boolean;
      booking_delete: boolean;
      booking_update_guest_version: boolean;
      email_offer_email: boolean;
      email_resend_ticket_email: boolean;
      email_resend_booking_confirmation_email: boolean;
      email_booking_updated_confirmation_email: boolean;
      email_cancellation_confirmation_email: boolean;
      email_cancellation_notification_email: boolean;
      email_quote_reminder_email: boolean;
      add_slot_type_public_offer: boolean;
      add_slot_type_exclusive_offer: boolean;
      add_slot_type_vehicle_consultation_offer: boolean;
    };
    export type OrderItem = {
      id: string;
      state: App.Enums.Orders.OrderItemStateEnum;
      slot?: App.Http.Resources.Dashboard.Bookings.Slot;
      versioned_article?: App.Http.Resources.Dashboard.Settings.Articles.Article;
      versioned_article_price?: App.Http.Resources.Dashboard.Settings.Articles.ArticlePrice;
      discount_reason?: App.Http.Resources.Dashboard.Settings.DiscountReason;
      ticket_preview_url?: string;
      created_at: string;
      updated_at: string;
      aggregated_id: string;
      vc_booking?: App.Http.Resources.Dashboard.VehicleConsultations.Booking;
    };
    export type Order = {
      id: string;
      booking_reference: string;
      guest_id: string;
      guest_version_id: string;
      state: any;
      derived_order_type: App.Enums.Orders.DerivedOrderTypeEnum;
      total_net_price: number;
      total_tax_price: number;
      total_gross_price: number;
      payment_service_provider: App.Enums.Orders.Payment.PaymentServiceProviderEnum;
      versioned_guest?: App.Http.Resources.Dashboard.Guest;
      items?: Array<App.Http.Resources.Dashboard.Orders.OrderItem>;
      aggregated_items?: Array<App.Http.Resources.Dashboard.Orders.AggregatedOrderItem>;
      cancelled_aggregated_items?: Array<App.Http.Resources.Dashboard.Orders.AggregatedOrderItem>;
      created_at: string;
      updated_at: string;
      customer_service_proposal_valid_until: string | null;
      customer_service_comment: string | null;
      customer_service_state: App.Enums.Orders.OrderCustomerServiceStateEnum | null;
      customer_service_proposal_payment_type: App.Enums.Orders.OrderCustomerServiceProposalPaymentTypeEnum | null;
      current_version: number;
      edit_state: App.Enums.Orders.OrderEditStateEnum;
      actions: App.Http.Resources.Dashboard.Orders.OrderAction;
      ordered_via_sales_channel: App.Enums.Settings.SalesChannelEnum;
      cancellation_reason_id: string | null;
      cancellation_reason?: App.Http.Resources.Dashboard.Settings.CancellationReason;
      order_booking_questions?: Array<App.Http.Resources.Ticketshop.Orders.OrderBookingQuestion>;
      locked_for_accounting_sync: boolean;
      payment?: App.Http.Resources.Dashboard.Orders.Payment.Payment;
      reservation_pickup_code: string | null;
      related_order_id: string | null;
      payment_cost_center?: string | null;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
    };
  }
  export namespace App.Http.Resources.Dashboard.Orders.Payment {
    export type Payment = {
      id: string;
      order_id: string;
      type: App.Enums.Orders.Payment.PaymentTypeEnum;
      state: any;
      payment_service_provider: App.Enums.Orders.Payment.PaymentServiceProviderEnum;
      payment_provider: App.Enums.Orders.Payment.PaymentProviderEnum | null;
      payment_method_type: App.Enums.Orders.Payment.PaymentMethodTypeEnum | null;
      payment_provider_id: string;
      payment_provider_transaction_id: string | null;
      payment_method_details: object | null;
      completed_at: string | null;
    };
  }
  export namespace App.Http.Resources.Dashboard.Reporting {
    export type ReportingFilter = {
      field: string;
      type: App.Enums.TableConfigColumnTypeEnum;
      operator: App.Enums.Reporting.ReportingFilterOperatorTypeEnum;
    };
    export type ReportingTemplate = {
      id: string;
      dataset: App.Enums.Reporting.ReportingDatasetEnum;
      type: App.Enums.Reporting.ReportingTemplateTypeEnum;
      title: App.Data.Meta.MultilingualAttributeData;
      required_filters: Array<App.Http.Resources.Dashboard.Reporting.ReportingFilter>;
      optional_filters: Array<App.Http.Resources.Dashboard.Reporting.ReportingFilter>;
      enforced_filters: Array<App.Http.Resources.Dashboard.Reporting.ReportingFilter>;
      created_at: string;
      updated_at: string;
    };
  }
  export namespace App.Http.Resources.Dashboard.Resources {
    export type Guide = {
      id: string;
      state: App.Enums.ModelStateEnum;
      first_name: string;
      last_name: string;
      department: string;
      phone_number: string;
      bmw_employee_number: string | null;
      email: string;
      is_guide: boolean;
      is_genius: boolean;
      guide_setup_time: number;
      genius_setup_time: number;
      created_at: string;
      updated_at: string;
      language_skill_ids?: Array<App.Data.Meta.UuidAttributeData>;
      language_skill_titles?: Array<App.Data.Meta.TitleAttributeData>;
      article_ids?: Array<App.Data.Meta.UuidAttributeData>;
      article_titles?: Array<App.Data.Meta.TitleAttributeData>;
      image?: App.Http.Resources.Dashboard.Attachment;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
      genius_qualifications?: Array<string>;
    };
  }
  export namespace App.Http.Resources.Dashboard.Settings {
    export type BookingQuestionAnswerOption = {
      id: string;
      latest_version_id: string;
      answer_option: App.Data.Meta.MultilingualAttributeData;
      created_at: string;
      updated_at: string;
    };
    export type BookingQuestion = {
      id: string;
      latest_version_id: string;
      state: App.Enums.ModelStateEnum;
      question: App.Data.Meta.MultilingualAttributeData;
      response_type: App.Enums.Settings.BookingQuestionResponseTypeEnum;
      is_required: boolean;
      is_multiselect: boolean;
      answer_options: Array<App.Http.Resources.Dashboard.Settings.BookingQuestionAnswerOption> | null;
      created_at: string;
      updated_at: string;
    };
    export type CancellationReason = {
      id: string;
      title: App.Data.Meta.MultilingualAttributeData;
      state: App.Enums.ModelStateEnum;
      created_at: string;
      updated_at: string;
      sales_channels: Array<App.Enums.Settings.SalesChannelEnum>;
    };
    export type DiscountReason = {
      id: string;
      latest_version_id: string;
      title: App.Data.Meta.MultilingualAttributeData;
      state: App.Enums.ModelStateEnum;
      reason_for_reduced_price: boolean;
      reason_for_free_price: boolean;
      created_at: string;
      updated_at: string;
      sales_channels: Array<any>;
      location_ids?: Array<App.Data.Meta.UuidAttributeData>;
      location_titles?: Array<App.Data.Meta.TitleAttributeData>;
    };
    export type LanguageSkill = {
      id: string;
      title: string;
      state: App.Enums.ModelStateEnum;
      created_at: string;
      updated_at: string;
    };
    export type Location = {
      id: string;
      title: string;
      street: string;
      house_number: string;
      zip_code: string;
      city: string;
      country: string;
      description: string | null;
      state: App.Enums.ModelStateEnum;
      created_at: string;
      updated_at: string;
      image?: App.Http.Resources.Dashboard.Attachment;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
    };
    export type MuseumScheduling = {
      article_id: string | null;
      venue_id: string | null;
      from: string;
      until: string;
      max_contingent: number;
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
      created_at: string;
      updated_at: string;
      type: App.Enums.Bookings.SlotTypeEnum;
      language: App.Enums.Bookings.SlotLanguageEnum | null;
    };
    export type Segment = {
      id: string;
      title: string;
      state: App.Enums.ModelStateEnum;
      created_at: string;
      updated_at: string;
    };
    export type SettlementAccount = {
      id: string;
      title: string;
      state: App.Enums.ModelStateEnum;
      cost_center_internal_payments: number;
      cost_center_external_payments: number;
      cost_unit: number;
      created_at: string;
      updated_at: string;
    };
    export type TableFilterFavorite = {
      id: string;
      title: string;
      filter: Array<any>;
      column_order: string | null;
      hidden_columns: string | null;
      per_page: number;
      sorts: string;
      table_id: string;
    };
    export type TargetAudience = {
      id: string;
      title: string;
      state: App.Enums.ModelStateEnum;
      created_at: string;
      updated_at: string;
    };
    export type Venue = {
      id: string;
      title: string;
      building_name: string;
      starting_point: string;
      street: string;
      house_number: string;
      zip_code: string;
      city: string;
      country: string;
      color: string;
      hints: string | null;
      additional_information_link: string | null;
      state: App.Enums.ModelStateEnum;
      created_at: string;
      updated_at: string;
      image?: App.Http.Resources.Dashboard.Attachment;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
    };
  }
  export namespace App.Http.Resources.Dashboard.Settings.Articles {
    export type ArticlePrice = {
      id: string;
      latest_version_id: string;
      title: App.Data.Meta.MultilingualAttributeData;
      description: App.Data.Meta.MultilingualAttributeData;
      net_price: number;
      gross_price: number;
      type: App.Enums.Settings.Article.ArticlePriceTypeEnum;
      tickets_per_price: number;
      min_quantity: number;
      max_quantity: number;
      is_time_based_discounted: boolean;
      time_based_discount_type: App.Enums.Settings.Article.TimeBasedDiscountTypesEnum | null;
      external_price_id: string;
      created_at: string;
      updated_at: string;
      group_id: number;
      group_item_order: number;
      valid_from: string;
      valid_until: string;
    };
    export type Article = {
      id: string;
      latest_version_id: string;
      state: App.Enums.ModelStateEnum;
      internal_title: string;
      title: App.Data.Meta.MultilingualAttributeData;
      short_description: App.Data.Meta.MultilingualAttributeData;
      description: App.Data.Meta.MultilingualAttributeData;
      external_article_id: string;
      location_id: string;
      location?: App.Http.Resources.Dashboard.Settings.Location;
      public_cancellation_period: number;
      includes_museum_admission: boolean;
      settlement_account_id: string;
      settlement_account?: App.Http.Resources.Dashboard.Settings.SettlementAccount;
      created_at: string;
      updated_at: string;
      slot_type: App.Enums.Bookings.SlotTypeEnum;
      sales_channels: Array<App.Enums.Settings.SalesChannelEnum>;
      segment_ids?: Array<App.Data.Meta.UuidAttributeData>;
      segment_titles?: Array<App.Data.Meta.TitleAttributeData>;
      segments?: Array<App.Http.Resources.Dashboard.Settings.Segment>;
      booking_question_ids?: Array<App.Data.Meta.UuidAttributeData>;
      booking_question_titles?: Array<App.Data.Meta.TitleAttributeData>;
      booking_questions?: Array<App.Http.Resources.Dashboard.Settings.BookingQuestion>;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
      prices?: Array<App.Http.Resources.Dashboard.Settings.Articles.ArticlePrice>;
      dashboard_category: App.Enums.Bookings.DashboardCategoryEnum;
      image?: App.Http.Resources.Dashboard.Attachment;
      online_shop_filter: Array<App.Enums.Settings.Article.OnlineShopFilterEnum>;
      ticket_shop_order: number | null;
      default_slot_duration_in_minutes: number | null;
    };
  }
  export namespace App.Http.Resources.Dashboard.VehicleConsultations {
    export type Availability = {
      id: string;
      start_at: string;
      end_at: string;
      created_at: string;
      updated_at: string;
    };
    export type AvailabilitySetting = {
      id: string;
      start_at: string;
      end_at: string;
      monday: boolean;
      tuesday: boolean;
      wednesday: boolean;
      thursday: boolean;
      friday: boolean;
      saturday: boolean;
      sunday: boolean;
      created_at: string;
      updated_at: string;
    };
    export type Booking = {
      id: string;
      cart_item_id: string | null;
      order_item_id: string | null;
      order_item?: App.Http.Resources.Dashboard.Orders.OrderItem;
      state: App.Enums.VehicleConsultations.BookingStateEnum;
      booking_type: App.Enums.VehicleConsultations.BookingTypeEnum;
      counselling_desk_id: string;
      counselling_desk?: App.Http.Resources.Dashboard.VehicleConsultations.CounsellingDesk.CounsellingDesk;
      guide_id: string;
      guide?: App.Http.Resources.Dashboard.Resources.Guide;
      vehicle_id: string;
      vehicle?: App.Http.Resources.Dashboard.VehicleConsultations.Vehicles.Vehicle;
      comparison_vehicle_id: string | null;
      comparison_vehicle?: App.Http.Resources.Dashboard.VehicleConsultations.Vehicles.Vehicle;
      is_vehicle_comparison: boolean;
      language?: App.Enums.Bookings.SlotLanguageEnum;
      guest_id: string | null;
      guest?: App.Http.Resources.Dashboard.Guest;
      order_id?: string | null;
      start_datetime_at: string;
      end_datetime_at: string;
      created_at: string;
      updated_at: string;
    };
  }
  export namespace App.Http.Resources.Dashboard.VehicleConsultations.CounsellingDesk {
    export type CounsellingDeskAvailability = {
      id: string;
      counselling_desk_id: string;
      start_at: string;
      end_at: string;
      created_at: string;
      updated_at: string;
    };
    export type CounsellingDesk = {
      id: string;
      state: App.Enums.ModelStateEnum;
      title: string;
      venue_id: string;
      digital_link: string | null;
      setup_time: number;
      created_at: string;
      updated_at: string;
      characteristics?: Array<string>;
      venue?: App.Http.Resources.Dashboard.Settings.Venue;
    };
    export type CounsellingDeskWithAvailabilityIndicator = {
      id: string;
      state: App.Enums.ModelStateEnum;
      title: string;
      venue_id: string;
      digital_link: string | null;
      setup_time: number;
      created_at: string;
      updated_at: string;
      has_availability: boolean;
      availability_start_at: string | null;
      availability_end_at: string | null;
      characteristics?: Array<string>;
    };
  }
  export namespace App.Http.Resources.Dashboard.VehicleConsultations.Genius {
    export type GeniusAvailability = {
      id: string;
      guide_id: string;
      guide: App.Http.Resources.Dashboard.Resources.Guide | null;
      type: App.Enums.VehicleConsultations.GeniusAvailabilityTypeEnum;
      available_at: string;
      created_at: string;
      updated_at: string;
    };
    export type GeniusWithAvailabilityIndicator = {
      id: string;
      state: App.Enums.ModelStateEnum;
      first_name: string;
      last_name: string;
      department: string;
      phone_number: string;
      bmw_employee_number: string | null;
      email: string;
      is_genius: boolean;
      genius_setup_time: number;
      created_at: string;
      updated_at: string;
      language_skill_ids?: Array<App.Data.Meta.UuidAttributeData>;
      language_skill_titles?: Array<App.Data.Meta.TitleAttributeData>;
      image?: App.Http.Resources.Dashboard.Attachment;
      attachments?: Array<App.Http.Resources.Dashboard.Attachment>;
      genius_qualifications?: Array<string>;
      has_availability: boolean;
      availability_type: App.Enums.VehicleConsultations.GeniusAvailabilityTypeEnum | null;
      availability_start_at: string | null;
      availability_end_at: string | null;
    };
  }
  export namespace App.Http.Resources.Dashboard.VehicleConsultations.Vehicles {
    export type VehicleAvailability = {
      id: string;
      type: App.Enums.VehicleConsultations.VehicleAvailabilityTypeEnum;
      vehicle_id: string;
      start_at: string;
      end_at: string;
      created_at: string;
      updated_at: string;
    };
    export type VehicleConstructionType = {
      id: string;
      vehicle_id: string;
      construction_type_key: App.Enums.VehicleConsultations.VehicleConstructionTypeEnum;
      available_for_brand: App.Enums.VehicleConsultations.VehicleBrandEnum;
      created_at: string;
      updated_at: string;
    };
    export type Vehicle = {
      id: string;
      state: App.Enums.ModelStateEnum;
      model_title: string;
      brand: App.Enums.VehicleConsultations.VehicleBrandEnum;
      series: App.Enums.VehicleConsultations.VehicleSeriesEnum;
      vin: string | null;
      fuel_type: App.Enums.VehicleConsultations.VehicleFuelTypeEnum;
      icons: Array<App.Enums.VehicleConsultations.VehicleIconTypeEnum>;
      color: App.Data.Meta.MultilingualAttributeData;
      setup_time: number;
      created_at: string;
      updated_at: string;
      construction_types?: Array<App.Enums.VehicleConsultations.VehicleConstructionTypeEnum>;
      detail_image?: App.Http.Resources.Dashboard.Attachment;
      thumbnail?: App.Http.Resources.Dashboard.Attachment;
      characteristics: Array<App.Enums.VehicleConsultations.VehicleCharacteristicEnum>;
    };
    export type VehicleWithAvailabilityIndicator = {
      id: string;
      state: App.Enums.ModelStateEnum;
      model_title: string;
      brand: App.Enums.VehicleConsultations.VehicleBrandEnum;
      series: App.Enums.VehicleConsultations.VehicleSeriesEnum;
      vin: string | null;
      fuel_type: App.Enums.VehicleConsultations.VehicleFuelTypeEnum;
      icons: Array<string>;
      color: App.Data.Meta.MultilingualAttributeData;
      setup_time: number;
      created_at: string;
      updated_at: string;
      has_online_availability: boolean;
      online_availability_start_at: string | null;
      online_availability_end_at: string | null;
      has_onsite_availability: boolean;
      onsite_availability_start_at: string | null;
      onsite_availability_end_at: string | null;
      construction_types?: Array<string>;
      detail_image?: App.Http.Resources.Dashboard.Attachment;
      thumbnail?: App.Http.Resources.Dashboard.Attachment;
    };
  }
  export namespace App.Http.Resources.Ticketshop {
    export type LocalizedMultiLanguageAttribute = {
      data: string;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Articles {
    export type ArticleAvailability = {
      unavailable_dates: Array<any>;
      available_dates: Array<any>;
      start_date: string;
      end_date: string;
    };
    export type ArticlePrice = {
      id: string;
      localized_title: string;
      localized_description: string;
      net_price: number;
      gross_price: number;
      type: App.Enums.Settings.Article.ArticlePriceTypeEnum;
      tickets_per_price: number;
      min_quantity: number;
      max_quantity: number;
      is_time_based_discounted: boolean;
      time_based_discount_type: App.Enums.Settings.Article.TimeBasedDiscountTypesEnum | null;
      group_id: number;
      group_item_order: number;
    };
    export type Article = {
      id: string;
      localized_title: string;
      localized_short_description: string;
      localized_description: string;
      image_url: string | null;
      location_id: string;
      includes_museum_admission: boolean;
      prices?: Array<App.Http.Resources.Ticketshop.Articles.ArticlePrice>;
      slots?: Array<App.Http.Resources.Ticketshop.Articles.ArticleSlot>;
    };
    export type ArticleSlot = {
      id: string;
      start_datetime: string;
      start_datetime_by_discount_type: string;
      end_datetime: string;
      language: App.Enums.Bookings.SlotLanguageEnum | null;
      dashboard_category: App.Enums.Bookings.DashboardCategoryEnum | null;
      remaining_contingent: number;
      grouping_key: string;
    };
    export type ArticlesByOnlineShopFilterAndDate = {
      data?: Array<App.Http.Resources.Ticketshop.Articles.Article>;
      used_location_ids: Array<any>;
    };
    export type BookingQuestionAnswerOption = {
      id: string;
      localized_answer: string;
    };
    export type BookingQuestion = {
      id: string;
      localized_question: string;
      response_type: App.Enums.Settings.BookingQuestionResponseTypeEnum;
      is_required: boolean;
      is_multiselect: boolean;
      answer_options?: Array<App.Http.Resources.Ticketshop.Articles.BookingQuestionAnswerOption>;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Content {
    export type ContentElement = {
      id: string;
      title: string;
      localized_content: string;
    };
    export type Page = {
      id: string;
      slug: string;
      localized_content: string;
      localized_title: string;
      index_follow: boolean;
      localized_meta_description: string;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Locations {
    export type DiscountReason = {
      id: string;
      location_title: string;
      localized_title: string;
      reason_for_reduced_price: boolean;
      reason_for_free_price: boolean;
    };
    export type Location = {
      id: string;
      title: string;
      image_url: string | null;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Orders {
    export type AggregatedOrderItem = {
      aggregated_id: string;
      slot?: App.Http.Resources.Ticketshop.Articles.ArticleSlot;
      versioned_article?: App.Http.Resources.Ticketshop.Articles.Article;
      versioned_article_price?: App.Http.Resources.Ticketshop.Articles.ArticlePrice;
      discount_reason?: App.Http.Resources.Ticketshop.Locations.DiscountReason;
      quantity: number;
      vc_booking?: App.Http.Resources.Ticketshop.Orders.VehicleConsultations.Booking;
      tickets: Array<App.Http.Resources.Ticketshop.Orders.Ticket>;
    };
    export type CancellationReason = {
      id: string;
      localized_title: string;
    };
    export type OrderBookingQuestionAnswer = {
      id: string;
      booking_question_answer_id: string | null;
      localized_predefined?: string;
      custom: string | null;
    };
    export type OrderBookingQuestion = {
      id: string;
      order_id: string;
      booking_slot_id: string;
      booking_slot?: App.Http.Resources.Ticketshop.Articles.ArticleSlot;
      question_id: string;
      question_version_id: string;
      question?: string;
      answers?: Array<App.Http.Resources.Ticketshop.Orders.OrderBookingQuestionAnswer>;
    };
    export type Order = {
      id: string;
      booking_reference: string;
      guest_id: string | null;
      state: App.Enums.Orders.OrderStateEnum;
      total_net_price: number;
      total_tax_price: number;
      total_gross_price: number;
      payment_service_provider: App.Enums.Orders.Payment.PaymentServiceProviderEnum;
      payment: App.Http.Resources.Ticketshop.Orders.Payment.Payment;
      aggregated_items?: Array<App.Http.Resources.Ticketshop.Orders.AggregatedOrderItem>;
      cancellation_reason_id: string | null;
      cancellation_reason?: App.Http.Resources.Ticketshop.Orders.CancellationReason;
      batch_ticket_download_link: string | null;
      order_booking_questions?: Array<App.Http.Resources.Ticketshop.Orders.OrderBookingQuestion>;
      is_cancellation_allowed: boolean;
    };
    export type Ticket = {
      id: string;
      apple_wallet_download_link: string;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Orders.Cart {
    export type CartBookingQuestionAnswer = {
      id: string;
      booking_question_answer_id: string | null;
      localized_predefined?: string;
      custom: string | null;
    };
    export type CartBookingQuestion = {
      id: string;
      cart_id: string;
      booking_slot_id: string;
      booking_slot?: App.Http.Resources.Ticketshop.Articles.ArticleSlot;
      question_id: string;
      question_version_id: string;
      question?: string;
      answers?: Array<App.Http.Resources.Ticketshop.Orders.Cart.CartBookingQuestionAnswer>;
    };
    export type CartItem = {
      id: string;
      cart_id: string;
      booking_slot_id: string;
      booking_slot?: App.Http.Resources.Ticketshop.Articles.ArticleSlot;
      vc_booking?: App.Http.Resources.Ticketshop.VehicleConsultations.VehicleConsultationBooking;
      article_id: string;
      article?: App.Http.Resources.Ticketshop.Articles.Article;
      article_version_id: string;
      article_price_id: string;
      article_price?: App.Http.Resources.Ticketshop.Articles.ArticlePrice;
      article_price_version_id: string;
      discount_reason_id: string | null;
      discount_reason_version_id: string | null;
      discount_reason?: App.Http.Resources.Ticketshop.Locations.DiscountReason;
      quantity: number;
    };
    export type Cart = {
      id: string;
      state: any;
      expires_at: string;
      guest_id: string | null;
      total_net_price: number;
      total_tax_price: number;
      total_gross_price: number;
      cart_items: Array<App.Http.Resources.Ticketshop.Orders.Cart.CartItem>;
      cart_booking_questions: Array<App.Http.Resources.Ticketshop.Orders.Cart.CartBookingQuestion>;
      phone_number_required: boolean;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Orders.Email {
    export type OrderEmail = {
      id: string;
      type: App.Enums.Administrations.EmailTypeEnum;
      localized_subject: string;
      localized_pre_content: string;
      localized_content: string;
      localized_post_content: string;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Orders.Payment {
    export type PaymentOptions = {
      id: string;
      pspId: string;
      name: string;
      storePaymentAccountAllowed: boolean;
      pspData: object | Array<any>;
      transactionTypes: Array<any>;
    };
    export type Payment = {
      id: string;
      order_id: string;
      type: App.Enums.Orders.Payment.PaymentTypeEnum;
      state: any;
      payment_service_provider: App.Enums.Orders.Payment.PaymentServiceProviderEnum;
      payment_provider: App.Enums.Orders.Payment.PaymentProviderEnum | null;
      payment_method_type: App.Enums.Orders.Payment.PaymentMethodTypeEnum | null;
      payment_provider_id: string;
      payment_provider_transaction_id: string | null;
      action_url: string | null;
      payment_options: Array<App.Http.Resources.Ticketshop.Orders.Payment.PaymentOptions> | null;
      payment_method_details: object | null;
      payment_auth_data: object | null;
      completed_at: string | null;
    };
  }
  export namespace App.Http.Resources.Ticketshop.Orders.VehicleConsultations {
    export type Booking = {
      id: string;
      state: App.Enums.VehicleConsultations.BookingStateEnum;
      booking_type: App.Enums.VehicleConsultations.BookingTypeEnum;
      vehicle_id: string;
      vehicle?: App.Http.Resources.Ticketshop.VehicleConsultations.Vehicles.Vehicle;
      comparison_vehicle_id: string | null;
      comparison_vehicle?: App.Http.Resources.Ticketshop.VehicleConsultations.Vehicles.Vehicle;
      is_vehicle_comparison: boolean;
      language?: App.Enums.Bookings.SlotLanguageEnum;
      start_datetime_at: string;
      end_datetime_at: string;
      digital_vehicle_consultation_link: string | null;
    };
  }
  export namespace App.Http.Resources.Ticketshop.VehicleConsultations {
    export type VehicleConsultationAvailability = {
      unavailable_dates: Array<any>;
      available_dates: Array<any>;
      start_date: string;
      end_date: string;
    };
    export type VehicleConsultationBooking = {
      id: string;
      cart_item_id: string;
      order_item_id: string | null;
      state: App.Enums.VehicleConsultations.BookingStateEnum;
      booking_type: App.Enums.VehicleConsultations.BookingTypeEnum;
      vehicle_id: string;
      vehicle?: any;
      comparison_vehicle_id: string | null;
      comparison_vehicle?: any;
      is_vehicle_comparison: boolean;
      start_datetime_at: string;
      end_datetime_at: string;
    };
  }
  export namespace App.Http.Resources.Ticketshop.VehicleConsultations.Import {
    export type AvailabilityImportStatus = {
      status: App.Enums.VehicleConsultations.GeniusAvailabilityImportStatusTypeEnum;
      errors: Array<App.Data.Meta.MultilingualAttributeData>;
    };
  }
  export namespace App.Http.Resources.Ticketshop.VehicleConsultations.Vehicles {
    export type AvailableConsultationSlot = {
      id: string;
      available_slot_id: string;
      generated_start_at: string;
      generated_end_at: string;
    };
    export type AvailableConsultations = {
      available_slots: Array<App.Http.Resources.Ticketshop.VehicleConsultations.Vehicles.AvailableConsultationSlot>;
      unavailable_dates_remaining_month: Array<any>;
      available_dates_remaining_month: Array<any>;
      start_date: string;
      end_date: string;
      booking_type: App.Enums.VehicleConsultations.BookingTypeEnum;
      article_id: string;
      article_price_id: string;
    };
    export type CalculateMissingSlotsForVehicleFunction = {
      id: string;
      generated_id: string;
      server_time: string;
      start_at: string;
      end_at: string;
      generated_start_at: string;
      generated_end_at: string;
      used_length: string;
      consultation_location: string;
      consultation_type: string;
    };
    export type CheckDependentAvailabilitiesSlotsFunction = {
      server_time: string;
      start_at: string;
      end_at: string;
      available_genius_id: string;
      available_counselling_desk_id: string;
      available_vehicle_id: string;
      available_comparison_vehicle_id: string;
    };
    export type Vehicle = {
      id: string;
      model_title: string;
      brand: App.Enums.VehicleConsultations.VehicleBrandEnum;
      series: App.Enums.VehicleConsultations.VehicleSeriesEnum;
      fuel_type: App.Enums.VehicleConsultations.VehicleFuelTypeEnum;
      icons: Array<App.Enums.VehicleConsultations.VehicleIconTypeEnum>;
      color: App.Data.Meta.MultilingualAttributeData;
      construction_types?: Array<App.Enums.VehicleConsultations.VehicleConstructionTypeEnum>;
      detail_image?: App.Http.Resources.Dashboard.Attachment;
      thumbnail?: App.Http.Resources.Dashboard.Attachment;
      characteristics: Array<App.Enums.VehicleConsultations.VehicleCharacteristicEnum>;
      has_online_individual_consultation_capability: boolean;
      has_onsite_individual_consultation_capability: boolean;
      has_future_online_availabilities?: boolean;
      has_future_onsite_availabilities?: boolean;
    };
    export type VehicleSearch = {
      data: Array<App.Http.Resources.Ticketshop.VehicleConsultations.Vehicles.Vehicle>;
      available_filters: Array<any>;
      available_series: Array<any>;
    };
  }
}
