import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NGXLogger} from 'ngx-logger';

@Injectable()
export abstract class ApiService {
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param result - optional value to return as the observable result
   */
  protected error(message: string): void {
    if (!environment.production) {
      this.log.error('Api Service Error:' + message);
    }
  }

  protected constructor(protected http: HttpClient, public log: NGXLogger) {
  }
}
