import {Injectable, Injector} from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, retryWhen, scan, take, takeWhile, throwError, timer} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {NGXLogger} from 'ngx-logger';
import {CookieService} from 'ngx-cookie-service';
import {StorageService} from "../storage.service";

/**
 * This interceptor adds the correct API endpoint to each request.
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
    private readonly http: HttpClient;
    private log: NGXLogger;
    private cookieService: CookieService;
    private storageService: StorageService;
    // private readonly localeService: LocaleService
    private isRateLimited = new BehaviorSubject<boolean>(false);
    private remainingRequests = new BehaviorSubject<number>(60);
    private rateLimitResetTime = new BehaviorSubject<Date | null>(null);


    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (this.shouldBypass(req)) {
            return next.handle(req);
        }


        return this.waitForRateLimitReset().pipe(
            take(1),
            switchMap(() => {
                req = this.modifyRequest(req);
                return next.handle(req);
            }),
            retryWhen(errors =>
                errors.pipe(
                    scan((acc, error) => {
                        if (req.method === 'GET') {
                            return this.handleRateLimitError(error, acc);
                        } else {
                            throw error; // For non-GET requests, rethrow the error to avoid retry
                        }
                    }, 0),
                    takeWhile(retryCount => retryCount < 3)
                )
            ),
            catchError((error: HttpErrorResponse) => this.handleCsrfError(error)),
            tap((event: HttpEvent<unknown>) => {
                if (event instanceof HttpResponse) {
                    const remaining = event.headers.get('X-RateLimit-Remaining');
                    if (remaining) {
                        console.info('rate limiting remaining:', {remaining});
                        this.remainingRequests.next(parseInt(remaining));
                    }
                }
            })
        );
    }

    private waitForRateLimitReset(): Observable<number> {
        return this.rateLimitResetTime.pipe(
            take(1),
            switchMap(resetTime => {
                const now = new Date();
                if (resetTime !== null && resetTime > now) {
                    const delayMs = resetTime.getTime() - now.getTime();
                    console.warn('rate limited: waiting for reset time', {resetTime});
                    return timer(delayMs);
                } else {
                    const remaining = this.remainingRequests.getValue();

                    // add some delay if remaining requests are low
                    const startDelayWhenRemaining = 10;
                    if (remaining < startDelayWhenRemaining) {
                        // Calculate delay based on remaining requests
                        const baseDelay = 300; // Base delay in milliseconds
                        const additionalDelayPerRequest = 100; // Additional delay per request below threshold
                        const delayMultiplier = startDelayWhenRemaining - remaining;
                        const totalDelay = baseDelay + (delayMultiplier * additionalDelayPerRequest);

                        console.warn('Remaining requests are low, delaying by', totalDelay, 'ms');
                        return timer(totalDelay);
                    }
                    return timer(0); // No delay if not rate limited or reset time has passed
                }
            })
        );
    }

    private shouldBypass(req: HttpRequest<unknown>): boolean {
        const isLocalRequest = req.url.includes(`${window.location.protocol}//${window.location.host}`);
        const isTranslationFile = req.url.includes('i18l') || req.url.includes('assets/i18l');
        const isExternalDomain = req.url.startsWith('http') && !req.url.startsWith(environment.api.url);
        return isLocalRequest || isTranslationFile || isExternalDomain;
    }

    private modifyRequest(req: HttpRequest<unknown>): HttpRequest<unknown> {
        if (!req.url.startsWith('http')) {
            req = req.clone({url: environment.api.url + req.url});
        }

        const token = this.storageService.get('token');

        return req.clone({
            withCredentials: true,
            headers: req.headers.set('Authorization',  "Bearer " + token)
        });
    }

    private handleRateLimitError(error: HttpErrorResponse, acc: number): number {
        if (error.status !== 429) {
            throw error;
        }
        const retryAfter = error.headers.get('Retry-After');
        if (retryAfter) {
            const resetTime = new Date(new Date().getTime() + parseInt(retryAfter) * 1000);
            this.rateLimitResetTime.next(resetTime);
            this.isRateLimited.next(true);
            console.warn('rate limited: retry after', {resetTime});
        }


        return acc + 1;
    }

    private handleCsrfError(error: HttpErrorResponse): Observable<never> {
        if (error && error.status === 419) {
            console.warn('CSRF Token mismatch deleting cookie');
            this.cookieService.delete('XSRF-TOKEN', '/', environment.api.session_domain, true, 'Lax');
        }
        return throwError(error);
    }

    public constructor(public injector: Injector) {
        this.http = injector.get(HttpClient);
        this.log = injector.get(NGXLogger);
        this.cookieService = injector.get(CookieService);
        this.storageService = injector.get(StorageService);
        // this.localeService = injector.get(LocaleService);
    }
}
