import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";
import {NGXLogger} from "ngx-logger";
import {Observable, take} from "rxjs";
import {Injectable} from "@angular/core";
import {ReducedSlot} from "../../entities/ReducedSlot.entity";


@Injectable({
  providedIn: 'root'
})
export class SlotsService extends ApiService {

  public index(): Observable<ReducedSlot[]> {
    return this.http.get<ReducedSlot[]>('/slots').pipe(take(1));
  }

  constructor(
    public override http: HttpClient,
    public override log: NGXLogger,
  ) {
    super(http, log);
  }
}
