import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {MY_STORAGE_SERVICE, StorageService} from './storage.service'
import {LOCAL_STORAGE, SESSION_STORAGE} from 'ngx-webstorage-service'

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    StorageService,
    { provide: MY_STORAGE_SERVICE, useExisting: LOCAL_STORAGE }
  ],
  exports: []
})
export class ServicesModule {}
